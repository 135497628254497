import { Page } from '@/components/Page';

import { NewLocationPageContent } from './NewLocationPageContent';

export function NewLocationPage() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Locations',
          path: '/locations'
        },
      ]}
      title="Add Location"
    >
      <NewLocationPageContent />
    </Page>
  );
}
