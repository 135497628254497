import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { QueryApiResponseData, QuestionnaireAccessCode } from '@/types/api';

import { LinkCopier } from './LinkCopier';
import { QrCodePopup } from './QrCodePopup';

export function QuestionnaireAccessCodesListPageContent() {
  const { currentAccount, currentEmployerPrn } = useAppContext();
  const [currentDisplayedQrCode, setCurrentDisplayedQrCode] = useState<
    QuestionnaireAccessCode | null
  >(null);

  const { data } = useApi<QueryApiResponseData<QuestionnaireAccessCode>>({
    disable: currentEmployerPrn === undefined || currentEmployerPrn === null,
    path: `/questionnaire-access-codes?employerPrn=${currentEmployerPrn}&sortBy=name`,
  });

  const navigate = useNavigate();

  return data === undefined ? (
    <PageBodySkeleton />
  ) : (
    <>
      <QrCodePopup
        currentDisplayedQrCode={currentDisplayedQrCode}
        onClose={() => {
          setCurrentDisplayedQrCode(null);
        }}
      />
      <div className="flex flex-col gap-y-4">
        {currentAccount?.hasAgreement && (
          <div className="px-4 sm:px-6 md:px-0 self-end">
            <Button
              onClick={() => {
                navigate(
                  currentAccount.accountType === AccountType.SERVICE_PROVIDER
                    ? `/employers/${currentEmployerPrn}/questionnaire-access-codes/new`
                    : '/questionnaire-access-codes/new',
                );
              }}
            >
              <MaterialSymbol icon="add" />
              Add New Access Code
            </Button>
          </div>
        )}
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <Table>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderCell firstCol>Name</TableHeaderCell>
                  <TableHeaderCell>Job Role</TableHeaderCell>
                  <TableHeaderCell>Location(s)</TableHeaderCell>
                  <TableHeaderCell>Copy Link</TableHeaderCell>
                  <TableHeaderCell>QR Code</TableHeaderCell>
                  <TableHeaderCell lastCol>Enabled</TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                {data.data.map((accessCode) => (
                  <TableRow
                    className="align-top cursor-pointer"
                    key={accessCode.prn}
                    onClick={() => {
                      navigate(
                        currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                          ? `/employers/${currentEmployerPrn}/questionnaire-access-codes/${accessCode.prn}`
                          : `/questionnaire-access-codes/${accessCode.prn}`,
                        {
                          state: { accessCode },
                        },
                      );
                    }}
                  >
                    <TableCell firstCol header>
                      <Link
                        state={{ accessCode }}
                        to={
                          currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                            ? `/employers/${currentEmployerPrn}/questionnaire-access-codes/${accessCode.prn}`
                            : `/questionnaire-access-codes/${accessCode.prn}`
                        }
                      >
                        {accessCode.name}
                      </Link>
                    </TableCell>
                    <TableCell>{accessCode.jobRole.name}</TableCell>
                    <TableCell>
                      <LocationsSummary accessCode={accessCode} />
                    </TableCell>
                    <TableCell>
                      <LinkCopier as="link" accessCode={accessCode} />
                    </TableCell>
                    <TableCell>
                      <Link onClick={() => {
                        setCurrentDisplayedQrCode(accessCode);
                      }}>
                        <MaterialSymbol icon="qr_code" />
                      </Link>
                    </TableCell>
                    <TableCell lastCol>
                      {accessCode.isEnabled && (
                        <MaterialSymbol icon="check" />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

function LocationsSummary({
  accessCode,
}: {
  accessCode: QuestionnaireAccessCode;
}) {
  if (accessCode.allowAllLocations) {
    return <div className="italic">all locations</div>
  } else if (accessCode.locations.length === 1) {
    return accessCode.locations[0].name;
  } else {
    return <div className="italic">{accessCode.locations.length} locations</div>
  }
}
