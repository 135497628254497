export enum PhysicalExertionLevel {
  HEAVY = 'HEAVY',
  LIGHT = 'LIGHT',
  MODERATE = 'MODERATE',
}

export enum PpeType {
  EYE_PROTECTION = 'EYE_PROTECTION',
  FOOT_PROTECTION = 'FOOT_PROTECTION',
  FULL_BODY_COVERING = 'FULL_BODY_COVERING',
  FULL_BODY_POSITIVE_PRESSURE_SUIT = 'FULL_BODY_POSITIVE_PRESSURE_SUIT',
  HAND_PROTECTION = 'HAND_PROTECTION',
  HEAD_PROTECTION = 'HEAD_PROTECTION',
  HEARING_PROTECTION = 'HEARING_PROTECTION',
}

export enum RespiratorSize {
  MEDIUM = 'MEDIUM',
  MEDIUM_LARGE = 'MEDIUM_LARGE',
  LARGE = 'LARGE',
  LARGE_X_LARGE = 'LARGE_X_LARGE',
  REGULAR = 'REGULAR',
  SMALL = 'SMALL',
  SMALL_MEDIUM = 'SMALL_MEDIUM',
  X_LARGE = 'X_LARGE',
}

export enum RespiratorType {
  AIR_PURIFYING_DISPOSABLE = 'AIR_PURIFYING_DISPOSABLE',
  AIR_PURIFYING_REUSABLE_FULL_FACE = 'AIR_PURIFYING_REUSABLE_FULL_FACE',
  AIR_PURIFYING_REUSABLE_PARTIAL_FACE = 'AIR_PURIFYING_REUSABLE_PARTIAL_FACE',
  POWERED_AIR_PURIFYING_FULL_FACE = 'POWERED_AIR_PURIFYING_FULL_FACE',
  POWERED_AIR_PURIFYING_PARTIAL_FACE = 'POWERED_AIR_PURIFYING_PARTIAL_FACE',
  SUPPLIED_AIR_HOSE_FULL_FACE = 'SUPPLIED_AIR_HOSE_FULL_FACE',
  SUPPLIED_AIR_HOSE_PARTIAL_FACE = 'SUPPLIED_AIR_HOSE_PARTIAL_FACE',
  SUPPLIED_AIR_SCBA = 'SUPPLIED_AIR_SCBA',
}

export enum RespiratorUsageDuration {
  ESCAPE_ONLY = 'ESCAPE_ONLY',
  LESS_THAN_TWO_HOURS = 'LESS_THAN_TWO_HOURS',
  MORE_THAN_FOUR_HOURS = 'MORE_THAN_FOUR_HOURS',
  TWO_TO_FOUR_HOURS = 'TWO_TO_FOUR_HOURS',
}

export enum RespiratorUsageScenario {
  ESCAPE = 'ESCAPE',
  FIREFIGHTING = 'FIREFIGHTING',
  RESCUE = 'RESCUE',
  RESCUE_OVERSIGHT = 'RESCUE_OVERSIGHT',
  ROUTINE = 'ROUTINE',
  SITUATIONAL = 'SITUATIONAL',
}

export enum RespiratoryHazard {
  CHEMICALS = 'CHEMICALS',
  DUST = 'DUST',
  FUMES = 'FUMES',
  INFECTIOUS_AGENTS = 'INFECTIOUS_AGENTS',
  OXYGEN_DEFICIENT = 'OXYGEN_DEFICIENT',
  OXYGEN_ENRICHED = 'OXYGEN_ENRICHED',
  SENSITIZERS = 'SENSITIZERS',
  SMOKE = 'SMOKE',
  VAPORS = 'VAPORS',
}

export enum WorkEnvironment {
  CONFINED_SPACE = 'CONFINED_SPACE',
  EXTREME_COLD = 'EXTREME_COLD',
  EXTREME_HEAT = 'EXTREME_HEAT',
  EXTREME_HEIGHT = 'EXTREME_HEIGHT',
  EXTREME_HUMIDITY = 'EXTREME_HUMIDITY',
}
