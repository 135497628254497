import { Page } from '@/components/Page';

import { NewUserPageContent } from './NewUserPageContent';

export function NewUserPage() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Users',
          path: '/users'
        },
      ]}
      title="Add User"
    >
      <NewUserPageContent />
    </Page>
  );
}
