import { useContext } from 'react';

import { appContext, authContext, navContext } from './contexts';

export function useAppContext() {
  return useContext(appContext);
}

export function useAuthContext() {
  return useContext(authContext);
}

export function useNavContext() {
  return useContext(navContext);
}
