import { RadioGroup } from '@headlessui/react';
import { format } from 'date-fns/format';
import { useState } from 'react';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { DateInput } from '@/components/DateInput';
import { ErrorBanner } from '@/components/ErrorBanner';
import { FormGroup } from '@/components/FormGroup';
import { Link } from '@/components/Link';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { RadioGroupOption } from '@/components/RadioGroupOption';
import { SelectInput } from '@/components/SelectInput';
import { useAuthContext } from '@/features/app';
import { UseApiState } from '@/hooks/useApi';
import {
  CreateRespiratorFitTestRequestData,
  EmployerRespirator,
  InitializerAccount,
  QueryApiResponseData,
} from '@/types/api';
import { scrollToClass, scrollToId } from '@/utils/dom';
import { todayIso } from '@/utils/time';
import { ValidationError, isValidIsoDate } from '@/utils/validation';

import { RespiratorFitTestProtocol } from '../enums';
import { CreateRespiratorFitTestFormData, CreateRespiratorFitTestFormErrors } from '../types';

import { EmailResultToEmployeeInput } from './EmailResultToEmployeeInput';
import { FitFactorInput } from './FitFactorInput';
import { RespiratorSizeInput } from './RespiratorSizeInput';
import { ResultInput } from './ResultInput';
import { SubmitSuccess } from './SubmitSuccess';

export function FitTestDetailsCard({
  account,
  employerRespiratorsApiState,
  formData,
  setFormData,
  setSubmitState,
  submitState,
}: {
  account?: InitializerAccount;
  employerRespiratorsApiState: UseApiState<QueryApiResponseData<EmployerRespirator>>;
  formData: CreateRespiratorFitTestFormData;
  setFormData: (_: CreateRespiratorFitTestFormData) => void;
  setSubmitState: (_: 'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS') => void;
  submitState: 'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS';
}) {
  const [validationErrors, setValidationErrors] = useState<CreateRespiratorFitTestFormErrors>({});

  const {
    data: respiratorsQuery,
    failed: respiratorsFailed,
    isLoading: respiratorsIsLoading,
  } = employerRespiratorsApiState;

  const { getAccessToken } = useAuthContext();

  function validate(): CreateRespiratorFitTestRequestData {
    let requestData: Partial<CreateRespiratorFitTestRequestData> = {
      emailResultToEmployee: formData.emailResultToEmployee,
    };
    const newErrors: CreateRespiratorFitTestFormErrors = {};

    if (formData.employerRespirator !== null) {
      requestData.employerRespirator = {
        prn: formData.employerRespirator.prn,
      };
    } else {
      newErrors.employerRespirator = 'This field is required.';
    }

    if (formData.protocol !== null) {
      if (formData.protocol === RespiratorFitTestProtocol.QUANTITATIVE) {
        if (formData.quantitativeFitFactor.trim().length > 0) {
          if (/^(0|[1-9][0-9]*)$/.test(formData.quantitativeFitFactor)) {
            const numericFitFactor = parseInt(formData.quantitativeFitFactor);

            if (numericFitFactor >= 0 && numericFitFactor <= 1000000) {
              requestData = {
                ...requestData,
                protocol: formData.protocol,
                quantitativeFitFactor: numericFitFactor,
              };
            } else {
              newErrors.quantitativeFitFactor = 'Fit factor must be between 1 and 1000000.';
            }
          } else {
            newErrors.quantitativeFitFactor = 'This field is invalid.';
          }
        } else {
          newErrors.quantitativeFitFactor = 'This field is required.';
        }
      } else {
        if (formData.result !== null) {
          requestData = {
            ...requestData,
            protocol: formData.protocol,
            result: formData.result,
          };
        } else {
          newErrors.result = 'This field is required.';
        }
      }
    } else {
      newErrors.protocol = 'This field is required.';
    }

    if (formData.respiratorMedicalEvaluation !== null) {
      requestData.respiratorMedicalEvaluation = {
        prn: formData.respiratorMedicalEvaluation.prn,
      };
    } else {
      newErrors.respiratorMedicalEvaluation = 'This field is required.';
    }

    if (formData.respiratorSize !== null) {
      requestData.respiratorSize = formData.respiratorSize;
    } else {
      newErrors.respiratorSize = 'This field is required.';
    }

    if (isValidIsoDate({
      date: formData.testDate,
      max: todayIso(),
      // TODO | min
    })) {
      requestData.testDate = formData.testDate;
    } else {
      newErrors.testDate = 'This field is invalid.';
    }

    if (account?.accountType === 'SERVICE_PROVIDER') {
      requestData.serviceProvider = {
        prn: account.prn,
      };
    } else {
      requestData.serviceProvider = null;
    }

    if (Object.keys(newErrors).length > 0) {
      throw new ValidationError(newErrors);
    }

    return requestData as CreateRespiratorFitTestRequestData;
  }

  async function submit() {
    const accessToken = await getAccessToken();

    let requestData: CreateRespiratorFitTestRequestData;

    try {
      requestData = validate();
      setValidationErrors({});
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        // Unexpected error type
        throw err;
      }

      setValidationErrors(err.errors as CreateRespiratorFitTestFormErrors);
      scrollToClass('form-error');

      return;
    }

    setSubmitState('SUBMITTING');

    try {
      const result = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/respirator-fit-tests`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          method: 'POST',
          body: JSON.stringify(requestData),
        },
      );

      if (result.status !== 201) {
        throw new Error();
      }

      setSubmitState('SUCCESS');
      scrollToId('success-banner');
    } catch (err) {
      setSubmitState('ERROR');
      scrollToClass('error-banner');
    }
  }

  return respiratorsIsLoading ? (
    <PageBodySkeleton />
  ) : respiratorsFailed ? (
    <ErrorBanner message="Respirators could not be loaded." />
  ) : (
    <>
      <Card id="fit-test-details">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col gap-y-10">
            <FormGroup error={validationErrors.employerRespirator} label="Respirator Model">
              {({ id }) => submitState === 'SUBMITTING' || submitState === 'SUCCESS' ? (
                <div>{formData.employerRespirator?.make} {formData.employerRespirator?.model}</div>
              ) : (
                <SelectInput<EmployerRespirator>
                  id={id}
                  onChange={(employerRespirator) => {
                    setFormData({
                      ...formData,
                      employerRespirator,
                    });
                  }}
                  options={respiratorsQuery.data.map((respirator) => ({
                    key: respirator.prn,
                    label: `${respirator.make} ${respirator.model}`,
                    value: respirator,
                  }))}
                  value={formData.employerRespirator}
                />
              )}
            </FormGroup>
            <FormGroup error={validationErrors.testDate} label="Test Date">
              {({ id }) => submitState === 'SUBMITTING' || submitState === 'SUCCESS' ? (
                <div>
                  {formData.testDate.substring(5, 7)}/
                  {formData.testDate.substring(8, 10)}/
                  {formData.testDate.substring(0, 4)}
                </div>
              ) : (
                <div className="flex gap-x-4 items-center justify-start">
                  <DateInput
                    id={id}
                    onChange={(testDate) => {
                      setFormData({
                        ...formData,
                        testDate,
                      });
                    }}
                    value={formData.testDate}
                  />
                  <Link onClick={() => {
                    setFormData({
                      ...formData,
                      testDate: format(new Date(), 'yyyy-MM-dd'),
                    });
                  }}>
                    Today
                  </Link>
                </div>
              )}
            </FormGroup>
            <FormGroup error={validationErrors.protocol} label="Fit Test Protocol">
              {(() => submitState === 'SUBMITTING' || submitState === 'SUCCESS' ? (
                <>
                  {formData.protocol !== null && (
                    <div>
                      {{
                        [RespiratorFitTestProtocol.QUALITATIVE_BITREX_SOLUTION_AEROSOL]: 'Qualitative - Bitrex®',
                        [RespiratorFitTestProtocol.QUALITATIVE_IRRITANT_SMOKE]: 'Qualitative - Irritant Smoke',
                        [RespiratorFitTestProtocol.QUALITATIVE_ISOAMYL_ACETATE]: 'Qualitative - Banana Oil',
                        [RespiratorFitTestProtocol.QUALITATIVE_SACCHARIN_SOLUTION_AEROSOL]: 'Qualitative - Saccharin Solution',
                        [RespiratorFitTestProtocol.QUANTITATIVE]: 'Quantitative Machine',
                      }[formData.protocol]}
                    </div>
                  )}
                </>
              ) : (
                <RadioGroup
                  className="flex flex-col gap-y-3"
                  onChange={(protocol) => {
                    if (protocol === null) {
                      setFormData({
                        ...formData,
                        protocol,
                        quantitativeFitFactor: null,
                        result: null,
                      });
                    } else if (protocol === RespiratorFitTestProtocol.QUANTITATIVE) {
                      setFormData({
                        ...formData,
                        protocol,
                        quantitativeFitFactor: '',
                        result: null,
                      });
                    } else {
                      setFormData({
                        ...formData,
                        protocol,
                        quantitativeFitFactor: null,
                      });
                    }
                  }}
                  value={formData.protocol}
                >
                  <div className="flex flex-col gap-y-1">
                    <div className="text-sm">Quantitative (QNFT)</div>
                    <div>
                      <RadioGroupOption value={RespiratorFitTestProtocol.QUANTITATIVE}>
                        QUANTITATIVE MACHINE
                      </RadioGroupOption>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <div className="text-sm">Qualitative (QLFT)</div>
                    <div className="grid grid-cols-2 gap-2 lg:grid-cols-4">
                      <RadioGroupOption value={RespiratorFitTestProtocol.QUALITATIVE_BITREX_SOLUTION_AEROSOL}>
                        <div className="flex flex-col items-center">
                          <div>BITREX®</div>
                          <div className="font-normal text-xs text-center">(Denatonium Benzoate)</div>
                        </div>
                      </RadioGroupOption>
                      <RadioGroupOption value={RespiratorFitTestProtocol.QUALITATIVE_IRRITANT_SMOKE}>
                        <div className="flex flex-col items-center">
                          <div>IRRITANT SMOKE</div>
                          <div className="font-normal text-xs text-center">(Stannic Chloride)</div>
                        </div>
                      </RadioGroupOption>
                      <RadioGroupOption value={RespiratorFitTestProtocol.QUALITATIVE_ISOAMYL_ACETATE}>
                        <div className="flex flex-col items-center">
                          <div>BANANA OIL</div>
                          <div className="font-normal text-xs text-center">(Isoamyl Acetate)</div>
                        </div>
                      </RadioGroupOption>
                      <RadioGroupOption value={RespiratorFitTestProtocol.QUALITATIVE_SACCHARIN_SOLUTION_AEROSOL}>
                        <div className="flex flex-col items-center">
                          <div className="text-center">SACCHARIN SOLUTION</div>
                        </div>
                      </RadioGroupOption>
                    </div>
                  </div>
                </RadioGroup>
              ))}
            </FormGroup>
            <RespiratorSizeInput
              error={validationErrors.respiratorSize}
              formData={formData}
              setFormData={setFormData}
              submitState={submitState}
            />
            <ResultInput
              error={validationErrors.result}
              formData={formData}
              setFormData={setFormData}
              submitState={submitState}
            />
            <FitFactorInput
              error={validationErrors.quantitativeFitFactor}
              formData={formData}
              setFormData={setFormData}
              submitState={submitState}
            />
            <EmailResultToEmployeeInput
              formData={formData}
              setFormData={setFormData}
              submitState={submitState}
            />
          </div>
        </div>
      </Card>
      <div className="flex flex-col gap-y-4">
        {submitState === 'SUCCESS' ? (
          <SubmitSuccess
            formData={formData}
            setFormData={setFormData}
            setSubmitState={setSubmitState}
          />
        ) : (
          <>
            {submitState === 'ERROR' && (
              <ErrorBanner message="Could not submit fit test. Please check your internet connection and try again." />
            )}
            <div className="flex justify-center">
              <Button
                disabled={submitState === 'SUBMITTING'}
                loading={submitState === 'SUBMITTING'}
                onClick={() => {
                  void submit();
                }}
                size="lg"
              >
                Submit Fit Test
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
