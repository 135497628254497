export enum NameSuffix {
  II = 'II',
  III = 'III',
  IV = 'IV',
  JR = 'JR',
  SR = 'SR',
  V = 'V',
}

export const NAME_SUFFIX_LABELS = {
  [NameSuffix.II]: 'II',
  [NameSuffix.III]: 'III',
  [NameSuffix.IV]: 'IV',
  [NameSuffix.JR]: 'Jr.',
  [NameSuffix.SR]: 'Sr.',
  [NameSuffix.V]: 'V',
}
