import { SelectOptionData } from '@/types/data';

export function SelectInput<T>({
  className = '',
  id,
  name,
  nullable = false,
  onChange,
  options,
  value,
}: {
  className?: string;
  id?: string;
  name?: string;
  nullable?: boolean,
  onChange?: (value: T | null) => void;
  options: SelectOptionData<T>[];
  value: T | null;
}) {
  function keyByValue(lookupValue: T | null) : string {
    return lookupValue === null
      ? ''
      : options.find((option) => option.value === lookupValue)?.key ?? '';
  }

  function valueByKey(lookupKey: string): T | null {
    return options.find((option) => option.key === lookupKey)?.value ?? null;
  }

  const selectedKey = keyByValue(value);

  return (
    <select
      className={`
        block w-full rounded-md border-0 py-1.5 pl-3 pr-10 bg-gray-900
        ring-1 ring-inset ring-gray-800
        focus:ring-2 focus:ring-purple-800
        ${className}
      `}
      id={id}
      name={name}
      onChange={(evt) => {
        if (onChange !== undefined) {
          onChange(valueByKey(evt.target.value));
        }
      }}
      value={selectedKey}
    >
      {(nullable || value === null) && (
        <option value=""></option>
      )}
      {options.map((option) => (
        <option key={option.key} value={option.key}>{option.label}</option>
      ))}
    </select>
  );
}
