import { createContext } from 'react';

import { InitializerAccount } from '@/types/api';

export type AppContextValue = {
  accounts?: never;
  currentAccount?: never;
  currentEmployerPrn?: never;
  failed?: never;
  isLoading: true;
  setCurrentAccount?: never;
} | {
  accounts?: never;
  currentAccount?: never;
  currentEmployerPrn?: never;
  failed: true;
  isLoading: false;
  setCurrentAccount?: never;
} | {
  accounts: InitializerAccount[];
  currentAccount?: InitializerAccount;
  currentEmployerPrn: string | null;
  failed: false;
  isLoading: false;
  setCurrentAccount: (_: InitializerAccount) => void;
};

export const appContext = createContext<AppContextValue>({} as AppContextValue);

type AuthContextValue = {
  getAccessToken: () => Promise<string>;
  logOut: () => void;
  user: {
    name: string;
  };
};

export const authContext = createContext<AuthContextValue>({} as AuthContextValue);

type NavContextValue = {
  mainNavOpen: boolean;
  setMainNavOpen: (_: boolean) => void;
};

export const navContext = createContext<NavContextValue>({} as NavContextValue);
