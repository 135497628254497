export function NumberTextInput({
  className = '',
  maxLength,
  onChange,
  placeholder,
  value,
}: {
  className?: string,
  maxLength?: number;
  onChange: (_: string) => void;
  placeholder?: string;
  value: string;
}) {
  return (
    <input
      className={`
        block rounded-md border-0 py-1.5 bg-gray-900
        shadow-sm ring-1 ring-inset ring-gray-800 placeholder:text-gray-700
        focus:ring-2 focus:ring-purple-800
        ${className}
      `}
      inputMode="numeric"
      maxLength={maxLength}
      onChange={(evt) => {
        const numbersOnlyValue = evt.target.value.replace(/[^0-9]/g, '');
        onChange(numbersOnlyValue);
      }}
      placeholder={placeholder}
      type="text"
      value={value}
    />
  );
}
