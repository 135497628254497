import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { Location, QueryApiResponseData } from '@/types/api';

export function LocationsListPageContent() {
  const { currentAccount, currentEmployerPrn } = useAppContext();
  const navigate = useNavigate();

  const { data } = useApi<QueryApiResponseData<Location>>({
    disable: currentEmployerPrn === undefined || currentEmployerPrn === null,
    path: `/locations?employerPrn=${currentEmployerPrn}&sortBy=name`,
  });

  return data === undefined ? (
    <PageBodySkeleton />
  ) : (
    <div className="flex flex-col gap-y-4">
      <div className="px-4 sm:px-6 md:px-0 self-end">
        <Button
          onClick={() => {
            navigate(
              currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                ? `/employers/${currentEmployerPrn}/locations/new`
                : '/locations/new',
            );
          }}
        >
          <MaterialSymbol icon="add" />
          Add New Location
        </Button>
      </div>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell firstCol lastCol>Name</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {data.data.map((location) => (
            <TableRow
              className="cursor-pointer"
              key={location.prn}
              onClick={() => {
                navigate(
                  currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                    ? `/employers/${currentEmployerPrn}/locations/${location.prn}`
                    : `/locations/${location.prn}`,
                  {
                    state: { location },
                  },
                );
              }}
            >
              <TableCell firstCol header lastCol>
                <Link
                  state={{ location }}
                  to={
                    currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                      ? `/employers/${currentEmployerPrn}/locations/${location.prn}`
                      : `/locations/${location.prn}`
                  }
                >
                  {location.name}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
