import { RadioGroup } from '@headlessui/react';
import { ReactNode } from 'react';

export function RadioGroupOption({
  children,
  className = '',
  disabled: optionDisabled,
  value,
}: {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  value: string;
}) {
  return (
    <RadioGroup.Option
      className={({ active, checked, disabled: groupDisabled }) => `
        flex items-center justify-center rounded-md p-3 text-sm font-semibold sm:flex-1
        ${active ? 'ring-2 ring-purple-800 ring-offset-2 ring-offset-gray-920': ''}
        ${checked
          ? 'bg-purple-800 hover:bg-purple-700'
          : 'bg-gray-900 ring-1 ring-inset ring-gray-800 hover:bg-gray-800'
        }
        ${(groupDisabled || optionDisabled) === true ? 'cursor-not-allowed opacity-25' : 'cursor-pointer focus:outline-none'}
        ${className}
      `}
      disabled={optionDisabled}
      value={value}
    >
      {children}
    </RadioGroup.Option>
  );
}
