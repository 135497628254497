export function SelectGroup<T extends string>({
  className = '',
  notaValue,
  onChange,
  options,
  value,
}: {
  className?: string;
  notaValue?: T;
  onChange: (value: T[]) => void;
  options: {
    disabled?: boolean;
    key: string;
    label: string;
    value: T;
  }[];
  value: T[];
}) {
  return (
    <div className={className}>
      {options.map((option) => {
        const checked = value.includes(option.value);

        return (
          <div
            className={`
              flex items-center justify-center rounded-md p-3 text-sm font-semibold whitespace-nowrap
              ${checked
                ? 'bg-purple-800 hover:bg-purple-700'
                : 'bg-gray-900 ring-1 ring-inset ring-gray-800 hover:bg-gray-800'
              }
              ${option.disabled === true ? 'cursor-not-allowed opacity-25' : 'cursor-pointer focus:outline-none'}
            `}
            key={option.value}
            onClick={() => {
              if (!option.disabled) {
                if (option.value === notaValue) {
                  if (value.length === 1 && value[0] === notaValue) {
                    onChange([]);
                  } else {
                    onChange([option.value]);
                  }
                } else if (!checked) {
                  onChange([...value, option.value].filter((val) => val !== notaValue));
                } else {
                  onChange(value.filter((val) => val !== option.value));
                }
              }
            }}
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );
}
