import { Page } from '@/components/Page';

import { LocationsListPageContent } from './LocationsListPageContent';

export function LocationsListPage() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Locations"
    >
      <LocationsListPageContent />
    </Page>
  );
}
