import { Page } from '@/components/Page';

import { NewJobRolePageContent } from './NewJobRolePageContent';

export function NewJobRolePage() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Job Roles',
          path: '/job-roles'
        },
      ]}
      title="Add Job Role"
    >
      <NewJobRolePageContent />
    </Page>
  );
}
