import { format } from 'date-fns/format';
import { useEffect, useId, useState } from 'react';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { DateInput } from '@/components/DateInput';
import { ErrorBanner } from '@/components/ErrorBanner';
import { Link } from '@/components/Link';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Page } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableRow,
} from '@/components/Table';
import { TextInput } from '@/components/TextInput';
import { AccountType } from '@/features/accounts';
import { useAppContext, useAuthContext } from '@/features/app';
import { RespiratorFitTestResult } from '@/features/respirator-fit-tests';
import { RespiratorMedicalEvaluationStatus } from '@/features/respirator-medical-evaluations';
import { useApi } from '@/hooks/useApi';
import {
  Employer,
  GeneratePresignedDocumentUrlResponseData,
  InitializerAccount,
  JobRole,
  Location,
  ProximaRecord,
  QueryApiResponseData,
} from '@/types/api';
import { openDownload } from '@/utils/dom';
import { NAME_SUFFIX_LABELS } from '@/utils/pii';
import { isValidIsoDate } from '@/utils/validation';

import { RecordSubtype } from '..';

type SortField =
  | 'completionTime'
  | 'creationTime'
  | 'employeeLastName'
  | 'employerPreferredName'
  | 'jobRoleName'
  | 'locationName'
  | 'status'
  | 'subtype';

type SortDir = '' | '-';

const rftResultLabels = {
  [RespiratorFitTestResult.FAIL]: 'Fail',
  [RespiratorFitTestResult.PASS]: 'Pass',
}

const rmeStatusLabels = {
  [RespiratorMedicalEvaluationStatus.APPROVED]: 'Approved',
  [RespiratorMedicalEvaluationStatus.DEFERRED]: 'Deferred',
  [RespiratorMedicalEvaluationStatus.EXPIRED_INTERVIEW]: 'Expired',
  [RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED]: 'Partially Approved',
  [RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW]: 'Interview Needed',
  [RespiratorMedicalEvaluationStatus.PENDING_REVIEW]: 'In Progress',
};

export function ReportsPage() {
  const [page, setPage] = useState(1);
  const [enteredSearch, setEnteredSearch] = useState('');
  const [committedSearch, setCommittedSearch] = useState('');
  const [committedCompletionDateEnd, setCommittedCompletionDateEnd] = useState<string | null>(
    null,
  );
  const [committedCompletionDateStart, setCommittedCompletionDateStart] = useState<string | null>(
    null,
  );
  const [completionDateEnd, setCompletionDateEnd] = useState<string>('');
  const [completionDateStart, setCompletionDateStart] = useState<string>('');
  const [employerFilters, setEmployerFilters] = useState<Record<string, boolean>>({});
  const [jobRoleFilters, setJobRoleFilters] = useState<Record<string, boolean>>({});
  const [locationFilters, setLocationFilters] = useState<Record<string, boolean>>({});
  const [rmeStatusFilters, setRmeStatusFilters] = useState({
    [RespiratorMedicalEvaluationStatus.APPROVED]: false,
    [RespiratorMedicalEvaluationStatus.DEFERRED]: false,
    [RespiratorMedicalEvaluationStatus.EXPIRED_INTERVIEW]: false,
    [RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED]: false,
    [RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW]: false,
    [RespiratorMedicalEvaluationStatus.PENDING_REVIEW]: false,
  });
  const [sortField, setSortField] = useState<SortField>('creationTime');
  const [sortDir, setSortDir] = useState<SortDir>('-');
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [csvStatus, setCsvStatus] = useState<'READY' | 'LOADING' | 'ERROR'>('READY');

  const { currentAccount } = useAppContext();
  const { getAccessToken } = useAuthContext();

  const queryString = getQueryString({
    committedCompletionDateEnd,
    committedCompletionDateStart,
    committedSearch,
    currentAccount,
    employerFilters,
    jobRoleFilters,
    locationFilters,
    page,
    rmeStatusFilters,
    sortField,
    sortDir,
  });

  const {
    data: employersQuery,
  } = useApi<QueryApiResponseData<Employer>>({
    disable: currentAccount?.accountType !== AccountType.SERVICE_PROVIDER,
    path: `/employers?serviceProviderPrn=${currentAccount?.prn}&sortBy=name`,
  });

  let singleEmployerPrn: string | null = null;

  if (currentAccount?.accountType === AccountType.EMPLOYER) {
    singleEmployerPrn = currentAccount.prn;
  } else {
    // Only available if exactly one employer is selected
    const selectedEmployerPrns = Object.entries(employerFilters)
      .filter((entry) => entry[1] /* The value (whether selected) */)
      .map((entry) => entry[0] /* The key (PRN) */);

      if (selectedEmployerPrns.length === 1) {
        singleEmployerPrn = selectedEmployerPrns[0];
      }
  }

  const {
    data: locationsQuery,
    isLoading: locationsIsLoading,
  } = useApi<QueryApiResponseData<Location>>({
    disable: singleEmployerPrn === null,
    path: `/locations?employerPrn=${singleEmployerPrn}&sortBy=name`,
  });

  const {
    data: jobRolesQuery,
    isLoading: jobRolesIsLoading,
  } = useApi<QueryApiResponseData<JobRole>>({
    disable: singleEmployerPrn === null,
    path: `/job-roles?employerPrn=${singleEmployerPrn}&sortBy=name`,
  });

  const {
    data: recordsQuery,
    failed,
  } = useApi<QueryApiResponseData<ProximaRecord>>({
    disable: currentAccount === undefined,
    path: `/records${queryString}`,
  });

  const [cachedRecordsQuery, setCachedRecordsQuery] = useState(recordsQuery);
  const [cachedLocations, setCachedLocations] = useState<Location[]>(locationsQuery?.data ?? []);
  const [cachedJobRoles, setCachedJobRoles] = useState<JobRole[]>(jobRolesQuery?.data ?? []);

  useEffect(() => {
    if (completionDateStart === '') {
      setCommittedCompletionDateStart(null);
    } else if (isValidIsoDate({ date: completionDateStart })) {
      setPage(1);
      setCommittedCompletionDateStart(completionDateStart);
    }
  }, [completionDateStart]);

  useEffect(() => {
    if (completionDateEnd === '') {
      setCommittedCompletionDateEnd(null);
    } else if (isValidIsoDate({ date: completionDateEnd })) {
      setPage(1);
      setCommittedCompletionDateEnd(completionDateEnd);
    }
  }, [completionDateEnd]);

  useEffect(() => {
    if (recordsQuery !== undefined) {
      setCachedRecordsQuery(recordsQuery);
    }
  }, [recordsQuery]);

  useEffect(() => {
    if (employersQuery !== undefined) {
      setEmployerFilters(
        employersQuery.data.reduce(
          (acc, employer) => ({
            ...acc,
            [employer.prn]: false,
          }),
          {},
        ),
      );
    }
  }, [employersQuery]);

  function setLocations(locations: Location[]) {
    setLocationFilters(
      locations.reduce(
        (acc, location) => ({
          ...acc,
          [location.prn]: false,
        }),
        {},
      ),
    );

    setCachedLocations(locations);
  }

  useEffect(() => {
    if (locationsQuery !== undefined) {
      setLocations(locationsQuery.data);
    }
  }, [locationsQuery]);

  function setJobRoles(jobRoles: JobRole[]) {
    setJobRoleFilters(
      jobRoles.reduce(
        (acc, jobRole) => ({
          ...acc,
          [jobRole.prn]: false,
        }),
        {},
      ),
    );

    setCachedJobRoles(jobRoles);
  }

  useEffect(() => {
    if (jobRolesQuery !== undefined) {
      setJobRoles(jobRolesQuery.data);
    }
  }, [jobRolesQuery]);

  function setWithPageReset<T>(setter: (_: T) => void) {
    return function(value: T) {
      setter(value);
      setPage(1);
    };
  }

  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Reports"
    >
    <div className="flex flex-col gap-y-4">
      <Card>
        <div className="flex flex-col gap-y-6">
          <form>
            <div className="flex gap-x-2">
              <TextInput
                className="grow"
                placeholder="Search Employees"
                value={enteredSearch}
                onChange={(evt) => {
                  setEnteredSearch(evt.target.value);
                }}
              />
              <Button
                onClick={(evt) => {
                  evt.preventDefault();
                  setPage(1);
                  setCommittedSearch(enteredSearch);
                }}
                type="submit"
              >
                Search
              </Button>
            </div>
          </form>
          <button
            className="font-semibold flex items-center"
            onClick={() => {
              setFiltersOpen(!filtersOpen);
            }}
            type="button"
          >
            <MaterialSymbol icon={filtersOpen ? 'arrow_drop_down' : 'arrow_right'} />
            Filters
          </button>
          <div className={`${filtersOpen ? '' : 'hidden'}`}>
            <div className="flex flex-col gap-y-2">
              <legend className="w-full text-sm font-semibold">Completion Date</legend>
              <div className="flex flex-wrap gap-x-4 gap-y-2 items-center">
                <div className="flex flex-col gap-y-1">
                  <span className="text-sm">Start</span>
                  <DateInput
                    onChange={(newCompletionDateStart) => {
                      setCompletionDateStart(newCompletionDateStart);
                    }}
                    value={completionDateStart}
                  />
                </div>
                <div className="flex flex-col gap-y-1">
                  <span className="text-sm">End</span>
                  <DateInput
                    onChange={(newCompletionDateEnd) => {
                      setCompletionDateEnd(newCompletionDateEnd);
                    }}
                    value={completionDateEnd}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${filtersOpen ? '' : 'hidden'}`}>
            <fieldset>
              <div className="flex flex-col gap-2">
                <legend className="text-sm font-semibold">Status</legend>
                <div className="flex flex-wrap gap-y-3 xl:gap-x-10">
                  <CheckboxFilter<RespiratorMedicalEvaluationStatus>
                    filterGroup={rmeStatusFilters}
                    label={rmeStatusLabels[RespiratorMedicalEvaluationStatus.APPROVED]}
                    setFilterGroup={setWithPageReset(setRmeStatusFilters)}
                    value={RespiratorMedicalEvaluationStatus.APPROVED}
                  />
                  <CheckboxFilter<RespiratorMedicalEvaluationStatus>
                    filterGroup={rmeStatusFilters}
                    label={rmeStatusLabels[RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED]}
                    setFilterGroup={setWithPageReset(setRmeStatusFilters)}
                    value={RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED}
                  />
                  <CheckboxFilter<RespiratorMedicalEvaluationStatus>
                    filterGroup={rmeStatusFilters}
                    label={rmeStatusLabels[RespiratorMedicalEvaluationStatus.PENDING_REVIEW]}
                    setFilterGroup={setWithPageReset(setRmeStatusFilters)}
                    value={RespiratorMedicalEvaluationStatus.PENDING_REVIEW}
                  />
                  <CheckboxFilter<RespiratorMedicalEvaluationStatus>
                    filterGroup={rmeStatusFilters}
                    label={rmeStatusLabels[RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW]}
                    setFilterGroup={setWithPageReset(setRmeStatusFilters)}
                    value={RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW}
                  />
                  <CheckboxFilter<RespiratorMedicalEvaluationStatus>
                    filterGroup={rmeStatusFilters}
                    label={rmeStatusLabels[RespiratorMedicalEvaluationStatus.DEFERRED]}
                    setFilterGroup={setWithPageReset(setRmeStatusFilters)}
                    value={RespiratorMedicalEvaluationStatus.DEFERRED}
                  />
                  <CheckboxFilter<RespiratorMedicalEvaluationStatus>
                    filterGroup={rmeStatusFilters}
                    label={rmeStatusLabels[RespiratorMedicalEvaluationStatus.EXPIRED_INTERVIEW]}
                    setFilterGroup={setWithPageReset(setRmeStatusFilters)}
                    value={RespiratorMedicalEvaluationStatus.EXPIRED_INTERVIEW}
                  />
                </div>
              </div>
            </fieldset>
          </div>
          <div className={`${filtersOpen ? '' : 'hidden'}`}>
            <fieldset>
              <div className="flex flex-col gap-2">
                <legend className="text-sm font-semibold">Employer</legend>
                <div className="flex flex-wrap gap-y-3 xl:gap-x-10">
                  {employersQuery?.data.map((employer) => (
                    <CheckboxFilter<string>
                      filterGroup={employerFilters}
                      key={employer.prn}
                      label={employer.preferredName}
                      setFilterGroup={(newEmployerFilters) => {
                        setEmployerFilters(newEmployerFilters);
                        setPage(1);

                        if (Object.values(newEmployerFilters).filter((v) => v).length !== 1) {
                          setLocations([]);
                          setJobRoles([]);
                        }
                      }}
                      value={employer.prn}
                    />
                  ))}
                </div>
              </div>
            </fieldset>
          </div>
          {singleEmployerPrn !== null && (
            <>
              <div className={`${filtersOpen ? '' : 'hidden'}`}>
                <fieldset>
                  <div className="flex flex-col gap-2">
                    <legend className="text-sm font-semibold">Location</legend>
                    {locationsIsLoading ? (
                      <div>
                        <MaterialSymbol className="animate-spin" icon="progress_activity" />
                      </div>
                    ) : locationsQuery?.data.length === 0 ? (
                      <div className="text-sm italic">
                        No locations found.
                      </div>
                    ) : (
                      <div className="flex flex-wrap gap-y-3 xl:gap-x-10">
                        {cachedLocations.map((location) => (
                          <CheckboxFilter<string>
                            filterGroup={locationFilters}
                            key={location.prn}
                            label={location.name}
                            setFilterGroup={setWithPageReset(setLocationFilters)}
                            value={location.prn}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </fieldset>
              </div>
              <div className={`${filtersOpen ? '' : 'hidden'}`}>
                <fieldset>
                  <div className="flex flex-col gap-2">
                    <legend className="text-sm font-semibold">Job Role</legend>
                    {jobRolesIsLoading ? (
                      <div>
                        <MaterialSymbol className="animate-spin" icon="progress_activity" />
                      </div>
                    ) : jobRolesQuery?.data.length === 0 ? (
                      <div className="text-sm italic">
                        No job roles found.
                      </div>
                    ) : (
                      <div className="flex flex-wrap gap-y-3 xl:gap-x-10">
                        {cachedJobRoles.map((jobRole) => (
                          <CheckboxFilter<string>
                            filterGroup={jobRoleFilters}
                            key={jobRole.prn}
                            label={jobRole.name}
                            setFilterGroup={setWithPageReset(setJobRoleFilters)}
                            value={jobRole.prn}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </fieldset>
              </div>
            </>
          )}
        </div>
      </Card>
      <div className="flex justify-between px-4 sm:px-6 md:px-0">
        <Button
          disabled={failed === true || csvStatus === 'LOADING'}
          onClick={() => {
            async function exportCsv() {
              const accessToken = await getAccessToken();

              const { page: _, ...otherQueryItems } = getQueryItems({
                committedCompletionDateEnd,
                committedCompletionDateStart,
                committedSearch,
                currentAccount,
                employerFilters,
                jobRoleFilters,
                locationFilters,
                page,
                rmeStatusFilters,
                sortField,
                sortDir,
              });

              try {
                setCsvStatus('LOADING');

                const result = await fetch(
                  `${import.meta.env.VITE_API_BASE_URL}/records/csv`,
                  {
                    headers: {
                      authorization: `Bearer ${accessToken}`,
                    },
                    method: 'POST',
                    body: JSON.stringify(otherQueryItems),
                  },
                );

                if (result.status !== 200) {
                  throw new Error();
                }

                const responseData = await result.json() as GeneratePresignedDocumentUrlResponseData;

                openDownload(responseData.presignedDocumentUrl.url);

                setCsvStatus('READY');
              } catch (err) {
                setCsvStatus('ERROR');
              }
            }

            void exportCsv();
          }}
        >
          <MaterialSymbol
            className={csvStatus === 'LOADING' ? 'animate-spin' : ''}
            icon={csvStatus === 'LOADING' ? 'progress_activity' : 'download'}
          />
          CSV
        </Button>
        <div className="flex items-center gap-x-2">
          {cachedRecordsQuery?.metadata.totalPages !== 0 && (
            <span>
              <span className="hidden sm:inline">Page </span>
              {page} of {cachedRecordsQuery?.metadata.totalPages ?? '...'}
            </span>
          )}
          <Button
            disabled={failed === true || page <= 1}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            <MaterialSymbol icon="chevron_left" />
          </Button>
          <Button
            disabled={failed === true || page === cachedRecordsQuery?.metadata.totalPages || cachedRecordsQuery?.metadata.totalPages === 0}
            onClick={() => {
              if (cachedRecordsQuery !== undefined && page < cachedRecordsQuery.metadata.totalPages) {
                setPage(page + 1);
              }
            }}
          >
            <MaterialSymbol icon="chevron_right" />
          </Button>
        </div>
      </div>
      {csvStatus === 'ERROR' && (
        <ErrorBanner message="Could not export CSV. Please check your internet connection and try again." />
      )}
      {failed ? (
        <ErrorBanner message="Could not load records. Please check your internet connection and try again." />
      ) : cachedRecordsQuery === undefined ? (
        <PageBodySkeleton />
      ) : (
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <Table>
                <TableHeader>
                  <TableHeaderRow>
                    <TableHeaderCell firstCol>
                      Type
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <a className="inline-flex" href="#" onClick={(evt) => {
                        evt.preventDefault();

                        setPage(1);

                        if (sortField === 'employeeLastName') {
                          setSortDir(sortDir === '' ? '-' : '');
                        } else {
                          setSortField('employeeLastName');
                          setSortDir('');
                        }
                      }}>
                        Name
                        {sortField === 'employeeLastName' && (
                          <MaterialSymbol icon={sortDir === '' ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
                        )}
                      </a>
                    </TableHeaderCell>
                    {currentAccount?.accountType === AccountType.SERVICE_PROVIDER && (
                      <TableHeaderCell>
                        <a className="inline-flex" href="#" onClick={(evt) => {
                          evt.preventDefault();

                          setPage(1);

                          if (sortField === 'employerPreferredName') {
                            setSortDir(sortDir === '' ? '-' : '');
                          } else {
                            setSortField('employerPreferredName');
                            setSortDir('');
                          }
                        }}>
                          Employer
                          {sortField === 'employerPreferredName' && (
                            <MaterialSymbol icon={sortDir === '' ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
                          )}
                        </a>
                      </TableHeaderCell>
                    )}
                    <TableHeaderCell>
                      <a className="inline-flex" href="#" onClick={(evt) => {
                        evt.preventDefault();

                        setPage(1);

                        if (sortField === 'locationName') {
                          setSortDir(sortDir === '' ? '-' : '');
                        } else {
                          setSortField('locationName');
                          setSortDir('');
                        }
                      }}>
                        Location
                        {sortField === 'locationName' && (
                          <MaterialSymbol icon={sortDir === '' ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
                        )}
                      </a>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <a className="inline-flex" href="#" onClick={(evt) => {
                        evt.preventDefault();

                        setPage(1);

                        if (sortField === 'jobRoleName') {
                          setSortDir(sortDir === '' ? '-' : '');
                        } else {
                          setSortField('jobRoleName');
                          setSortDir('');
                        }
                      }}>
                        Job Role
                        {sortField === 'jobRoleName' && (
                          <MaterialSymbol icon={sortDir === '' ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
                        )}
                      </a>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <a className="inline-flex" href="#" onClick={(evt) => {
                        evt.preventDefault();

                        setPage(1);

                        if (sortField === 'status') {
                          setSortDir(sortDir === '' ? '-' : '');
                        } else {
                          setSortField('status');
                          setSortDir('');
                        }
                      }}>
                        Status
                        {sortField === 'status' && (
                          <MaterialSymbol icon={sortDir === '' ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
                        )}
                      </a>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <a className="inline-flex" href="#" onClick={(evt) => {
                        evt.preventDefault();

                        setPage(1);

                        if (sortField === 'completionTime') {
                          setSortDir(sortDir === '' ? '-' : '');
                        } else {
                          setSortField('completionTime');
                          setSortDir('');
                        }
                      }}>
                        Completed
                        {sortField === 'completionTime' && (
                          <MaterialSymbol icon={sortDir === '' ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
                        )}
                      </a>
                    </TableHeaderCell>
                    <TableHeaderCell>Document</TableHeaderCell>
                    <TableHeaderCell>Re-send</TableHeaderCell>
                  </TableHeaderRow>
                </TableHeader>
                <TableBody>
                  {cachedRecordsQuery.data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} firstCol lastCol>No Records Found</TableCell>
                    </TableRow>
                  ) : cachedRecordsQuery.data.map((record) => (
                    <TableRow key={record.prn}>
                      <TableCell firstCol>
                        {record.subtype === RecordSubtype.RESPIRATOR_MEDICAL_EVALUATION ? 'MEQ' : 'FTR'}
                      </TableCell>
                      <TableCell header>
                        {record.employee.lastName}
                        ,{' '}
                        {record.employee.firstName}
                        {record.employee.nameSuffix !== null && (
                          ` ${NAME_SUFFIX_LABELS[record.employee.nameSuffix]}`
                        )}
                      </TableCell>
                      {currentAccount?.accountType === AccountType.SERVICE_PROVIDER && (
                        <TableCell>
                          {record.employer.preferredName}
                        </TableCell>
                      )}
                      <TableCell>
                        {record.location.name}
                      </TableCell>
                      <TableCell>
                        {record.jobRole.name}
                      </TableCell>
                      <TableCell>
                        {record.subtype === RecordSubtype.RESPIRATOR_MEDICAL_EVALUATION
                          ? rmeStatusLabels[record.status]
                          : rftResultLabels[record.result]
                        }
                      </TableCell>
                      <TableCell>
                        {record.subtype === RecordSubtype.RESPIRATOR_MEDICAL_EVALUATION
                          ? record.judgmentTime !== null
                            ? format(record.judgmentTime, 'MMMM d, yyyy')
                            : '-'
                          : format(record.testTime, 'MMMM d, yyyy')
                        }
                      </TableCell>
                      <TableCell>
                        {(record.subtype !== RecordSubtype.RESPIRATOR_MEDICAL_EVALUATION
                            || record.status === RespiratorMedicalEvaluationStatus.APPROVED
                            || record.status === RespiratorMedicalEvaluationStatus.DEFERRED)
                          ? <RecordDocumentLink record={record} />
                          : '-'
                        }
                      </TableCell>
                      <TableCell>
                        <ResendNotificationsLink record={record} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
}

function CheckboxFilter<T extends string>({
  filterGroup,
  label,
  setFilterGroup,
  value,
}: {
  filterGroup: Record<T, boolean>;
  label: string;
  setFilterGroup: (_: Record<T, boolean>) => void;
  value: T;
}) {
  const id = useId();

  return (
    <div className="w-full sm:w-48 xl:w-auto flex align-start items-center gap-x-2">
      <input
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        checked={filterGroup[value] ?? false}
        className="h-4 w-4 rounded border-gray-300 text-pink-700 focus:ring-pink-700 cursor-pointer"
        id={id}
        onChange={(evt) => {
          setFilterGroup({
            ...filterGroup,
            [value]: evt.target.checked,
          });
        }}
        type="checkbox"
      />
      <label className="text-sm cursor-pointer" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

function RecordDocumentLink({
  record,
}: {
  record: ProximaRecord;
}) {
  const { getAccessToken } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);

  return isLoading ? (
    <MaterialSymbol className="animate-spin" icon="progress_activity" />
  ) : (
    <Link onClick={() => {
      async function openLink() {
        if (!isLoading) {
          const accessToken = await getAccessToken();
    
          setIsLoading(true);
    
          const recordSubtypePath = record.subtype === RecordSubtype.RESPIRATOR_MEDICAL_EVALUATION
            ? 'presigned-respirator-medical-evaluation-document-urls'
            : 'presigned-respirator-fit-test-document-urls';
    
          const recordSubtypeKey = record.subtype === RecordSubtype.RESPIRATOR_MEDICAL_EVALUATION
            ? 'respiratorMedicalEvaluation'
            : 'respiratorFitTest';
    
          try {
            const result = await fetch(
              `${import.meta.env.VITE_API_BASE_URL}/${recordSubtypePath}`,
              {
                body: JSON.stringify({
                  [recordSubtypeKey]: {
                    prn: record.prn,
                  },
                }),
                headers: {
                  authorization: `Bearer ${accessToken}`,
                },
                method: 'POST',
              },
            );
    
            const responseData = await result.json() as GeneratePresignedDocumentUrlResponseData;

            openDownload(responseData.presignedDocumentUrl.url);

            setIsLoading(false);
          } catch (err) {
            setIsLoading(false);
          }
        }
      }

      void openLink();
    }}>
      <MaterialSymbol icon="download" />
    </Link>
  );
}

function ResendNotificationsLink({
  record,
}: {
  record: ProximaRecord;
}) {
  const { getAccessToken } = useAuthContext();
  const [state, setState] = useState<'READY' | 'SENDING' | 'SENT' | 'ERROR'>('READY');

  async function resendNotifications() {
    if (state === 'READY') {
      const accessToken = await getAccessToken();

      setState('SENDING');

      const recordSubtypePath = record.subtype === RecordSubtype.RESPIRATOR_MEDICAL_EVALUATION
        ? 'respirator-medical-evaluations/send-notifications'
        : 'respirator-fit-tests/send-notifications';

      const recordSubtypeKey = record.subtype === RecordSubtype.RESPIRATOR_MEDICAL_EVALUATION
        ? 'respiratorMedicalEvaluation'
        : 'respiratorFitTest';

      try {
        await Promise.all([
          fetch(
            `${import.meta.env.VITE_API_BASE_URL}/${recordSubtypePath}`,
            {
              body: JSON.stringify({
                [recordSubtypeKey]: {
                  prn: record.prn,
                },
                sendToEmployee: true,
                sendToEnrolledNotifications: false,
              }),
              headers: {
                authorization: `Bearer ${accessToken}`,
              },
              method: 'POST',
            },
          ),
          // Display the loader for at least .25 seconds
          new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve();
            }, 250);
          }),
        ]);

        setState('SENT');
      } catch (err) {
        setState('ERROR');
      }

      setTimeout(() => {
        setState('READY');
      }, 2000);
    }
  }

  return state === 'READY' ? (
    <Link onClick={() => {
      void resendNotifications();
    }}>
      <MaterialSymbol icon="send" />
    </Link>
  ) : state === 'SENDING' ? (
    <MaterialSymbol className="animate-spin" icon="progress_activity" />
  ) : state === 'SENT' ? (
    <MaterialSymbol icon="check" />
  ) : (
    <MaterialSymbol icon="warning" />
  );
}

function getQueryItems({
  committedCompletionDateEnd,
  committedCompletionDateStart,
  committedSearch,
  currentAccount,
  employerFilters,
  jobRoleFilters,
  locationFilters,
  page,
  rmeStatusFilters,
  sortField,
  sortDir,
}: {
  committedCompletionDateEnd: string | null,
  committedCompletionDateStart: string | null,
  committedSearch: string;
  currentAccount?: InitializerAccount;
  employerFilters: Record<string, boolean>;
  jobRoleFilters: Record<string, boolean>;
  locationFilters: Record<string, boolean>;
  page: number,
  rmeStatusFilters: Record<RespiratorMedicalEvaluationStatus, boolean>;
  sortField: SortField;
  sortDir: SortDir;
}) {
  const queryItems: Record<string, string> = {};

  if (currentAccount?.accountType === AccountType.SERVICE_PROVIDER) {
    queryItems.serviceProviderPrn = currentAccount.prn;
  } else if (currentAccount?.accountType === AccountType.EMPLOYER) {
    queryItems.employerPrn = currentAccount.prn;
  }

  if (committedSearch.trim() !== '') {
    queryItems.employeeName = committedSearch.trim();
  }

  if (committedCompletionDateStart !== null) {
    queryItems.completionDateStart = committedCompletionDateStart;
  }

  if (committedCompletionDateEnd !== null) {
    queryItems.completionDateEnd = committedCompletionDateEnd;
  }

  const rmeStatuses: string[] = [];

  for (const [rmeStatus, selected] of Object.entries(rmeStatusFilters)) {
    if (selected) {
      rmeStatuses.push(rmeStatus);
    }
  }

  if (rmeStatuses.length > 0) {
    queryItems.respiratorMedicalEvaluationStatus = rmeStatuses.join(',');
  }

  const employerPrns: string[] = [];

  for (const [employerPrn, selected] of Object.entries(employerFilters)) {
    if (selected) {
      employerPrns.push(employerPrn);
    }
  }

  if (employerPrns.length > 0) {
    queryItems.employerPrn = employerPrns.join(',');
  }

  const locationPrns: string[] = [];

  for (const [locationPrn, selected] of Object.entries(locationFilters)) {
    if (selected) {
      locationPrns.push(locationPrn);
    }
  }

  if (locationPrns.length > 0) {
    queryItems.locationPrn = locationPrns.join(',');
  }

  const jobRolePrns: string[] = [];

  for (const [jobRolePrn, selected] of Object.entries(jobRoleFilters)) {
    if (selected) {
      jobRolePrns.push(jobRolePrn);
    }
  }

  if (jobRolePrns.length > 0) {
    queryItems.jobRolePrn = jobRolePrns.join(',');
  }

  queryItems.page = page.toString();
  queryItems.sortBy = `${sortDir}${sortField}`;

  return queryItems;
}

function getQueryString(queryData: {
  committedCompletionDateEnd: string | null,
  committedCompletionDateStart: string | null,
  committedSearch: string;
  currentAccount?: InitializerAccount;
  employerFilters: Record<string, boolean>;
  jobRoleFilters: Record<string, boolean>;
  locationFilters: Record<string, boolean>;
  page: number,
  rmeStatusFilters: Record<RespiratorMedicalEvaluationStatus, boolean>;
  sortField: SortField;
  sortDir: SortDir;
}) {
  const queryItems = getQueryItems(queryData);

  return `?${Object.entries(queryItems).map(([key, value]) => `${key}=${value}`).join('&')}`;
}
