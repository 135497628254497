import { QuestionnaireAccessCode } from '@/types/api';
import { slugify } from '@/utils/text';

export function generateQuestionnaireAccessLink({
  accessCode,
  includeSlug = false,
}: {
  accessCode: QuestionnaireAccessCode;
  includeSlug?: boolean;
}) {
  return `${
    import.meta.env.VITE_QUESTIONNAIRE_CLIENT_BASE_URL
  }${
    includeSlug
      ? `/${slugify(`${accessCode.employer.preferredName} ${accessCode.name}`)}`
      : ''
  }?accessCode=${accessCode.accessCode}`;
}
