import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Page } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableRow,
} from '@/components/Table';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { Employer, QueryApiResponseData } from '@/types/api';

export function EmployersListPage() {
  const { currentAccount } = useAppContext();
  const navigate = useNavigate();

  const { data, failed, isLoading } = useApi<QueryApiResponseData<Employer>>({
    disable: currentAccount === undefined,
    path: `/employers?serviceProviderPrn=${currentAccount?.prn}`,
  });

  // TODO | If current account not service provider, 404

  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/'
        },
      ]}
      title="Employers"
    >
      {isLoading ? (
        <PageBodySkeleton />
      ) : failed ? (
        // TODO
        <div>ERROR: Could not load employers.</div>
      ) : (
        <div className="flex flex-col gap-y-4">
          <div className="px-4 sm:px-6 md:px-0 self-end">
            <Button
              onClick={() => {
                navigate('/employers/new');
              }}
            >
              <MaterialSymbol icon="add" />
              Add New Employer
            </Button>
          </div>
          <Table>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell firstCol>Name</TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {data.data.map((employer) => (
                <TableRow
                  className="cursor-pointer"
                  key={employer.prn}
                  onClick={() => {
                    navigate(`/employers/${employer.prn}/locations`, {
                      state: { employer },
                    });
                  }}
                >
                  <TableCell firstCol lastCol>
                    <Link state={{ employer }} to={`/employers/${employer.prn}/locations`}>
                      {employer.preferredName}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </Page>
  );
}
