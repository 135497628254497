import { useEffect, useState } from 'react';

import { useAppContext, useAuthContext } from '@/features/app';

export type UseApiState<TData> = {
  data?: never;
  error?: never;
  failed?: never;
  isLoading: true;
} | {
  data?: never;
  error: Error;
  failed: true;
  isLoading: false;
} | {
  data: TData;
  error?: never;
  failed: false;
  isLoading: false;
}

export function useApi<TData>({
  disable = false,
  path,
  preInitialized = false,
}: {
  disable?: boolean;
  path: string;
  preInitialized?: boolean;
}): UseApiState<TData> {
  const { isLoading } = useAppContext();
  const { getAccessToken } = useAuthContext();

  const [state, setState] = useState<UseApiState<TData>>({
    isLoading: true,
  });

  useEffect(() => {
    async function fetchApiData() {
      if (!disable && (preInitialized || !isLoading)) {
        setState({
          isLoading: true,
        });

        try {
          const accessToken = await getAccessToken();

          const result = await fetch(`${import.meta.env.VITE_API_BASE_URL}${path}`, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          });

          const data = await result.json() as TData;

          setState({
            data,
            failed: false,
            isLoading: false,
          });
        } catch (err) {
          setState({
            error: err as Error,
            failed: true,
            isLoading: false,
          });
        }
      }
    }

    void fetchApiData();
  }, [disable, getAccessToken, isLoading, path, preInitialized]);

  return state;
}
