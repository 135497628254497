import { QuestionnaireAccessCode } from '@/types/api';
import { Dialog, Transition } from '@headlessui/react';
import html2canvas from 'html2canvas';
import { useId } from 'react';

import { Button } from '@/components/Button';
import { slugify } from '@/utils/text';

import { generateQuestionnaireAccessLink } from '../utils';

import { QrCode } from './QrCode';
import { MaterialSymbol } from '@/components/MaterialSymbol';

export function QrCodePopup({
  currentDisplayedQrCode,
  onClose,
}: {
  currentDisplayedQrCode: QuestionnaireAccessCode | null;
  onClose: () => void;
}) {
  const id = useId();

  return (
    <Transition show={currentDisplayedQrCode !== null}>
      <Dialog className="relative z-50" onClose={onClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
                {currentDisplayedQrCode !== null && (
                  <>
                    <div className="px-4 pt-4 pb-4 sm:px-6 sm:pt-6" id={id}>
                      <QrCode
                        employerName={currentDisplayedQrCode.employer.preferredName}
                        name={currentDisplayedQrCode.name}
                        url={generateQuestionnaireAccessLink({
                          accessCode: currentDisplayedQrCode
                        })}
                      />
                    </div>
                    <div className="px-4 pb-4 sm:px-6 sm:pb-6">
                      <Button
                        className="w-full"
                        onClick={() => {
                          async function saveImage() {
                            const code = currentDisplayedQrCode;
                            const el = document.getElementById(id);

                            if (code === null || el === null) {
                              return;
                            }

                            const canvas = await html2canvas(el);
                            const data = canvas.toDataURL('image/png');

                            const link = document.createElement('a');
                            link.style.display = 'none';
                            link.href = data;
                            link.download = `qr.${slugify(`${code.employer.preferredName} ${code.name}`)}.png`;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }

                          void saveImage();
                        }}
                        size="lg"
                      >
                        <MaterialSymbol icon="download" />
                        Download Image
                      </Button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
