export function DateInput({
  className = '',
  id,
  name,
  onChange,
  value,
}: {
  className?: string,
  id?: string;
  name?: string;
  onChange?: (_: string) => void;
  value?: string;
}) {
  return (
    <input
      className={`
        min-w-40
        block rounded-md border-0 py-1.5 bg-gray-900 shadow-sm
        ring-1 ring-inset ring-gray-800 placeholder:text-gray-700
        focus:ring-2 focus:ring-purple-800
        ${className}
      `}
      id={id}
      name={name}
      onChange={(evt) => {
        if (onChange !== undefined) {
          onChange(evt.target.value);
        }
      }}
      type="date"
      value={value}
    />
  );
}
