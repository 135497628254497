import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { useLocationState } from '@/hooks/useLocationState';
import { GetQuestionnaireAccessCodeData, QuestionnaireAccessCode } from '@/types/api';

import { QuestionnaireAccessCodeDetailsPageContent } from './QuestionnaireAccessCodeDetailsPageContent';

export function QuestionnaireAccessCodeDetailsPage() {
  const params = useParams();

  const { data } = useApi<GetQuestionnaireAccessCodeData>({
    path: `/questionnaire-access-codes/${params.questionnaireAccessCodePrn}`,
  });

  const locationState = useLocationState<{ questionnaireAccessCode: QuestionnaireAccessCode; }>();

  const [accessCode, setAccessCode] = useState<QuestionnaireAccessCode | null>(
    locationState?.questionnaireAccessCode ?? null,
  );

  useEffect(() => {
    if (data !== undefined) {
      // Bust react-router's cached state and replace it with the fetched data
      setAccessCode(data.questionnaireAccessCode);
      window.history.replaceState({}, '');
    }
  }, [data]);

  const { currentAccount } = useAppContext();

  if (currentAccount === undefined || accessCode === null) {
    return <PageBodySkeleton />;
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER) {
    return (
      <div className="flex flex-col gap-4">
        <div className="font-display text-purple-200 pl-4 sm:pl-6">{accessCode.name}</div>
        <QuestionnaireAccessCodeDetailsPageContent accessCode={accessCode} setAccessCode={setAccessCode} />
      </div>
    );
  } else {
    return <QuestionnaireAccessCodeDetailsPageWrapper accessCode={accessCode} setAccessCode={setAccessCode} />;
  }
}

export function QuestionnaireAccessCodeDetailsPageWrapper({
  accessCode,
  setAccessCode,
}: {
  accessCode: QuestionnaireAccessCode;
  setAccessCode: Dispatch<SetStateAction<QuestionnaireAccessCode | null>>;
}) {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Access Codes',
          path: '/questionnaire-access-codes'
        },
      ]}
      title={accessCode.name}
    >
      <QuestionnaireAccessCodeDetailsPageContent accessCode={accessCode} setAccessCode={setAccessCode} />
    </Page>
  );
}
