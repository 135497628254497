import { Button } from '@/components/Button';
import { SuccessBanner } from '@/components/SuccessBanner';
import { scrollToId } from '@/utils/dom';

import { CreateRespiratorFitTestFormData } from '../types';
import { RespiratorFitTestProtocol } from '../enums';

export function SubmitSuccess({
  formData,
  setFormData,
  setSubmitState,
}: {
  formData: CreateRespiratorFitTestFormData;
  setFormData: (_: CreateRespiratorFitTestFormData) => void;
  setSubmitState: (_: 'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS') => void;
}) {
  return (
    <div className="flex flex-col gap-y-8">
      <SuccessBanner id="success-banner" message="Fit test successfully submitted." />
      <div className="w-full max-w-2xl mx-auto flex flex-col gap-y-4 items-stretch">
        <div className="text-sm font-semibold text-center">ENTER A NEW FIT TEST:</div>
        <div className="px-4 sm:px-6 md:px-0 grid grid-cols-1 sm:grid-cols-3 gap-2">
          <Button
            onClick={() => {
              setFormData({
                ...formData,
                employerRespirator: null,
                protocol: null,
                quantitativeFitFactor: null,
                respiratorSize: null,
                result: null,
              });

              setSubmitState('UNSUBMITTED');
              scrollToId('fit-test-details');
            }}
            size="lg"
          >
            SAME EMPLOYEE
          </Button>
          <Button
            onClick={() => {
              setFormData({
                ...formData,
                respiratorMedicalEvaluation: null,
                respiratorSize: null,
                result: null,
                ...(formData.protocol === RespiratorFitTestProtocol.QUANTITATIVE
                  ? {
                    protocol: formData.protocol,
                    quantitativeFitFactor: '',
                  }
                  : {
                    protocol: formData.protocol,
                    quantitativeFitFactor: null,
                  }
                )
              });

              setSubmitState('UNSUBMITTED');
              scrollToId('employee-selection');
            }}
            size="lg"
          >
            SAME RESPIRATOR
          </Button>
          <Button
            onClick={() => {
              setFormData({
                ...formData,
                employerRespirator: null,
                protocol: null,
                quantitativeFitFactor: null,
                respiratorMedicalEvaluation: null,
                respiratorSize: null,
                result: null,
              });

              setSubmitState('UNSUBMITTED');
              scrollToId('employee-selection');
            }}
            size="lg"
          >
            START OVER
          </Button>
        </div>
      </div>
    </div>
  );
}
