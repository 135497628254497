import { MaterialSymbol } from '@/components/MaterialSymbol';

export function SuccessBanner({
  id,
  message,
}: {
  id?: string;
  message: string;
}) {
  return (
    <div
      className="md:rounded-md bg-green-200 p-4 sm:px-6 text-green-700 flex items-center gap-x-4"
      id={id}
    >
      <MaterialSymbol className="text-3xl font-semibold" icon="check" />
      <div className="flex flex-col items-start">
        <div className="text-sm font-semibold">SUCCESS</div>
        <div>{message}</div>
      </div>
    </div>
  );
}
