import { RadioGroup } from '@headlessui/react';

import { FormGroup } from '@/components/FormGroup';
import { RadioGroupOption } from '@/components/RadioGroupOption';

import { RespiratorFitTestProtocol, RespiratorFitTestResult } from '../enums';
import { CreateRespiratorFitTestFormData } from '../types';

export function ResultInput({
  error,
  formData,
  setFormData,
  submitState,
}: {
  error?: string;
  formData: CreateRespiratorFitTestFormData;
  setFormData: (_: CreateRespiratorFitTestFormData) => void;
  submitState: 'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS';
}) {
  return (
    formData.protocol === RespiratorFitTestProtocol.QUALITATIVE_BITREX_SOLUTION_AEROSOL
    || formData.protocol === RespiratorFitTestProtocol.QUALITATIVE_IRRITANT_SMOKE
    || formData.protocol === RespiratorFitTestProtocol.QUALITATIVE_ISOAMYL_ACETATE
    || formData.protocol === RespiratorFitTestProtocol.QUALITATIVE_SACCHARIN_SOLUTION_AEROSOL
   ) && (
    <FormGroup error={error} label="Result">
      {(() => submitState === 'SUBMITTING' || submitState === 'SUCCESS' ? (
        <div>
          {formData.result !== null && (
            <>
              {{
                [RespiratorFitTestResult.PASS]: 'Pass',
                [RespiratorFitTestResult.FAIL]: 'Fail',
              }[formData.result]}
            </>
          )}
        </div>
      ) : (
        <RadioGroup
          className="grid grid-cols-2 sm:grid-cols-4 gap-2"
          onChange={(result) => {
            setFormData({
              ...formData,
              result,
            });
          }}
          value={formData.result}
        >
          <RadioGroupOption value={RespiratorFitTestResult.PASS}>PASS</RadioGroupOption>
          <RadioGroupOption value={RespiratorFitTestResult.FAIL}>FAIL</RadioGroupOption>
        </RadioGroup>
      ))}
    </FormGroup>
  )
}
