import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { RESPIRATOR_TYPE_SHORT_LABELS } from '@/features/respiratory-protection/utils';
import { useApi } from '@/hooks/useApi';
import { EmployerRespirator, QueryApiResponseData } from '@/types/api';

export function EmployerRespiratorsListPageContent() {
  const { currentAccount, currentEmployerPrn } = useAppContext();
  const navigate = useNavigate();

  const { data } = useApi<QueryApiResponseData<EmployerRespirator>>({
    path: `/employer-respirators?employerPrn=${currentEmployerPrn}&sortBy=model`,
  });

  return data === undefined ? (
    <PageBodySkeleton />
  ) : (
    <div className="flex flex-col gap-y-4">
      <div className="px-4 sm:px-6 md:px-0 self-end">
        <Button
          onClick={() => {
            navigate(
              currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                ? `/employers/${currentEmployerPrn}/employer-respirators/new`
                : '/employer-respirators/new',
            );
          }}
        >
          <MaterialSymbol icon="add" />
          Add New Respirator
        </Button>
      </div>
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <Table>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell firstCol>Model</TableHeaderCell>
                <TableHeaderCell>Type</TableHeaderCell>
                <TableHeaderCell lastCol>Passing FF</TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {data.data.map((employerRespirator) => (
                <TableRow key={employerRespirator.prn}>
                  <TableCell firstCol header>{employerRespirator.make} {employerRespirator.model}</TableCell>
                  <TableCell>{RESPIRATOR_TYPE_SHORT_LABELS[employerRespirator.respiratorType]}</TableCell>
                  <TableCell lastCol>{employerRespirator.passingQuantitativeFitFactor}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

