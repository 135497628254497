import { ReactNode } from 'react';

export function FormLabel({
  children,
  htmlFor,
}: {
  children: ReactNode;
  htmlFor?: string;
}) {
  return (
    <label className="text-sm text-purple-200 font-semibold uppercase" htmlFor={htmlFor}>
      {children}
    </label>
  );
}
