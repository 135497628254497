import { RadioGroup } from '@headlessui/react';

import { FormGroup } from '@/components/FormGroup';
import { RadioGroupOption } from '@/components/RadioGroupOption';
import { RespiratorSize } from '@/features/respiratory-protection';

import { CreateRespiratorFitTestFormData } from '../types';

export function RespiratorSizeInput({
  error,
  formData,
  setFormData,
  submitState,
}: {
  error?: string;
  formData: CreateRespiratorFitTestFormData;
  setFormData: (_: CreateRespiratorFitTestFormData) => void;
  submitState: 'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS';
}) {
  return (
    <FormGroup error={error} label="Respirator Size">
      {(() => submitState === 'SUBMITTING' || submitState === 'SUCCESS' ? (
        <div>
          {formData.respiratorSize !== null && (
            <>
              {{
                [RespiratorSize.REGULAR]: 'Regular / One-Size',
                [RespiratorSize.SMALL]: 'Small',
                [RespiratorSize.SMALL_MEDIUM]: 'Small / Medium',
                [RespiratorSize.MEDIUM]: 'Medium',
                [RespiratorSize.MEDIUM_LARGE]: 'Medium / Large',
                [RespiratorSize.LARGE]: 'Large',
                [RespiratorSize.LARGE_X_LARGE]: 'Large / X-Large',
                [RespiratorSize.X_LARGE]: 'X-Large',
              }[formData.respiratorSize]}
            </>
          )}
        </div>
      ) : (
        <RadioGroup
          className="grid grid-cols-4 sm:grid-cols-7 gap-2"
          onChange={(respiratorSize) => {
            setFormData({
              ...formData,
              respiratorSize,
            });
          }}
          value={formData.respiratorSize}
        >
          <RadioGroupOption className="col-span-4 sm:col-span-7" value={RespiratorSize.REGULAR}>
            REGULAR / ONE-SIZE
          </RadioGroupOption>
          <RadioGroupOption value={RespiratorSize.SMALL}>S</RadioGroupOption>
          <RadioGroupOption value={RespiratorSize.SMALL_MEDIUM}>S/M</RadioGroupOption>
          <RadioGroupOption value={RespiratorSize.MEDIUM}>M</RadioGroupOption>
          <RadioGroupOption value={RespiratorSize.MEDIUM_LARGE}>M/L</RadioGroupOption>
          <RadioGroupOption value={RespiratorSize.LARGE}>L</RadioGroupOption>
          <RadioGroupOption value={RespiratorSize.LARGE_X_LARGE}>L/XL</RadioGroupOption>
          <RadioGroupOption value={RespiratorSize.X_LARGE}>XL</RadioGroupOption>
        </RadioGroup>
      ))}
    </FormGroup>
  )
}
