import { BreadcrumbData } from '@/types/data';

import { Breadcrumb } from './Breadcrumb';

export function Breadcrumbs({
  items,
}: {
  items: BreadcrumbData[];
}) {
  return (
    <nav aria-label="breadcrumbs" className="mb-2">
      <ol className="flex items-center space-x-1" role="list">
        {items.map((item) => (
          <Breadcrumb key={item.path} {...item} />
        ))}
      </ol>
    </nav>
  );
}
