import { ReactNode } from 'react';

export function PagePadder({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div className="md:px-6 lg:px-8">
      {children}
    </div>
  );
}
