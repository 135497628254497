import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';

import primaryLogoWhite from '@/assets/primary-logo-white.png';
import { MaterialSymbol } from '@/components/MaterialSymbol';

import { useAppContext, useNavContext } from '../hooks';

export function MainNav() {
  return (
    <>
      <MobileMainNav />
      <DesktopMainNav />
    </>
  );
}

function DesktopMainNav() {
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-72 lg:flex-col">
      <MainNavContent />
    </div>
  );
}

function MainNavContent() {
  const { currentAccount, failed, isLoading } = useAppContext();

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gradient-to-br from-pink-800 to-purple-900 px-6 py-4">
      <div className="flex h-16 shrink-0 items-center justify-center">
        <Link to="/dashboard">
          <img alt="Proxima" className="h-8 w-auto" src={primaryLogoWhite} />
        </Link>
      </div>
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7" role="list">
          <li>
            <ul className="-mx-2 space-y-1" role="list">
              {failed ? null : isLoading ? (
                <>
                  <MainNavLinkSkeleton />
                  <MainNavLinkSkeleton />
                  <MainNavLinkSkeleton />
                  <MainNavLinkSkeleton />
                  <MainNavLinkSkeleton />
                </>
              ) : currentAccount === undefined ? (
                null
              ) : (
                <>
                  <MainNavLink icon="home" label="Dashboard" to="/dashboard" />
                  <MainNavLink icon="leaderboard" label="Reports" to="/reports" />
                  {currentAccount.accountType === 'SERVICE_PROVIDER' && (
                    <MainNavLink icon="store" label="Employers" to="/employers" />
                  )}
                  {/* {currentAccount.accountType === 'EMPLOYER' && (
                    <MainNavLink icon="badge" label="Employees" to="/employees" />
                  )} */}
                  {currentAccount.accountType === 'EMPLOYER' && (
                    <MainNavLink icon="location_on" label="Locations" to="/locations" />
                  )}
                  {currentAccount.accountType === 'EMPLOYER' && (
                    <MainNavLink icon="work" label="Job Roles" to="/job-roles" />
                  )}
                  {currentAccount.accountType === 'EMPLOYER' && (
                    <MainNavLink icon="qr_code" label="Access Codes" to="/questionnaire-access-codes" />
                  )}
                  {currentAccount.accountType === 'EMPLOYER' && (
                    <MainNavLink icon="masks" label="Respirators" to="/employer-respirators" />
                  )}
                  <MainNavLink icon="manage_accounts" label="Users" to="/users" />
                  {/* <MainNavLink icon="settings" label="Settings" to="/settings" /> */}
                </>
              )}
            </ul>
          </li>
          {!failed && !isLoading && currentAccount !== undefined && (
            <li>
              <div className="text-xs font-semibold leading-6 text-gray-300 uppercase">Tasks</div>
              <ul className="-mx-2 mt-2 space-y-1" role="list">
                <MainNavLink icon="masks" label="Enter Fit Tests" to="/respirator-fit-tests/new" />
              </ul>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

function MainNavLink({
  icon,
  label,
  to,
}: {
  icon: string;
  label: string;
  to: string;
}) {
  const { setMainNavOpen } = useNavContext();

  return (
    <li>
      <NavLink
        className={({ isActive }) => `
          ${isActive
            ? 'text-gray-50 bg-gray-950/50'
            : 'text-gray-300 hover:text-gray-50 hover:bg-gray-950/30'
          }
          group flex items-center gap-x-3 rounded-md p-2
        `}
        onClick={() => { setMainNavOpen(false); }}
        to={to}
      >
        {({ isActive }) => (
          <>
            <MaterialSymbol
              className={`
                ${isActive
                  ? 'bg-gradient-to-br from-pink to-purple text-transparent bg-clip-text'
                  : ''
                }
                text-3xl leading-none
              `}
              icon={icon}
            />
            <span className="text-sm font-semibold">
              {label}
            </span>
          </>
        )}
      </NavLink>
    </li>
  );
}

function MainNavLinkSkeleton() {
  return (
    <div className="h-10 bg-gray-50/20 rounded-md animate-pulse" />
  )
}

function MobileMainNav() {
  const { mainNavOpen, setMainNavOpen } = useNavContext();

  return (
    <Transition.Root show={mainNavOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMainNavOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-950/95" />
        </Transition.Child>
        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    className="-m-2.5 p-2.5"
                    onClick={() => { setMainNavOpen(false); }}
                    type="button"
                  >
                    <span className="sr-only">close sidebar</span>
                    <span aria-hidden="true" className="block material-symbols-outlined">close</span>
                  </button>
                </div>
              </Transition.Child>
              <MainNavContent />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
