import { useId } from 'react';

import { CheckboxInput } from '@/components/CheckboxInput';
import { FormGroup } from '@/components/FormGroup';

import { CreateRespiratorFitTestFormData } from '../types';


export function EmailResultToEmployeeInput({
  formData,
  setFormData,
  submitState,
}: {
  formData: CreateRespiratorFitTestFormData;
  setFormData: (_: CreateRespiratorFitTestFormData) => void;
  submitState: 'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS';
}) {
  const id = useId();

  return (
    <FormGroup label="Send Result to Employee">
      {(() => submitState === 'SUBMITTING' || submitState === 'SUCCESS' ? (
        <div>
          {formData.emailResultToEmployee ? 'Yes' : 'No'}
        </div>
      ) : (
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <CheckboxInput
              checked={formData.emailResultToEmployee}
              id={id}
              onChange={(emailResultToEmployee) => {
                setFormData({
                  ...formData,
                  emailResultToEmployee,
                });
              }}
            />
          </div>
          <div className="ml-3 leading-6">
            <label
              className="cursor-pointer"
              htmlFor={id}
            >
              Email a copy of this fit test result to the employee
            </label>
          </div>
        </div>
      ))}
    </FormGroup>
  );
}
