import { ReactNode } from 'react';

export function Table({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div className="bg-gray-920 md:rounded-md overflow-hidden">
      <table className="min-w-full">
        {children}
      </table>
    </div>
  );
}
