import { useLocation } from 'react-router-dom';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any --
 * react-router's state explicitly accepts any value
**/
export function useLocationState<TState extends Record<string, any>>(): TState | null {
  const location = useLocation();

  if (typeof location.state !== 'object' || location.state === null) {
    return null;
  }

  return location.state as TState;
}
