import { MouseEventHandler, ReactNode } from 'react';

import { MaterialSymbol } from './MaterialSymbol';

export function Button({
  children,
  className = '',
  disabled,
  loading,
  onClick,
  size = 'md',
  type = 'button',
}: {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: 'md' | 'lg';
  type?: 'button' | 'submit';
}) {
  return (
    <button 
      className={`
        rounded-md bg-green-700 hover:bg-green-600
        text-sm font-semibold
        focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700
        ${size === 'md' ? 'px-2.5 py-1.5' : ''}
        ${size === 'lg' ? 'p-3' : ''}
        ${disabled === true ? 'cursor-not-allowed opacity-25' : ''}
        ${className}
      `}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      <div className="flex items-center justify-center gap-x-2">
        {children}
        {loading && (
          <MaterialSymbol className="animate-spin text-base leading-none" icon="progress_activity" />
        )}
      </div>
    </button>
  );
}
