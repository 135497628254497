import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

export function IndexRedirector() {
  const { isLoading } = useAuth0();

  // Not checking for auth status here causes an infinite redirect loop
  if (!isLoading) {
    return <Navigate to="/dashboard" />;
  } else {
    return null;
  }
}
