import { ReactNode } from 'react';
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';

export function Link({
  children,
  className = '',
  onClick,
  state,
  to,
}: {
  children: ReactNode;
  className?: string;
} & ({
  onClick: () => void;
  state?: never;
  to?: never;
} | {
  onClick?: never;
  state?: LinkProps['state'],
  to: string;
})) {
  const fullClassName = `text-green font-semibold hover:underline ${className}`;

  if (onClick !== undefined) {
    return (
      <a
        className={fullClassName}
        href="#"
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          onClick();
        }}
      >
        {children}
      </a>
    );
  } else {
    return (
      <ReactRouterLink
        className={fullClassName}
        onClick={(evt) => {
          evt.stopPropagation();
        }}
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment --
        * react-router's state explicitly accepts any value
        **/
        state={state}
        to={to}
      >
        {children}
      </ReactRouterLink>
    );
  }
}
