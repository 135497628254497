import { useState } from 'react';

import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { QuestionnaireAccessCode } from '@/types/api';

import { generateQuestionnaireAccessLink } from '../utils';

export function LinkCopier({
  accessCode,
  as,
}: {
  accessCode: QuestionnaireAccessCode;
  as: 'button' | 'link';
}) {
  const [state, setState] = useState<'READY' | 'LOADING' | 'LOADED'>('READY');

  const Component = as === 'button' ? Button : Link;

  return (
    <Component onClick={() => {
      async function copyLinkWithUiFeedback() {
        setState('LOADING');

        await Promise.all([
          navigator.clipboard.writeText(
            generateQuestionnaireAccessLink({
              accessCode,
              includeSlug: true,
            }),
          ),
          // Display the loader for at least .25 seconds
          new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve();
            }, 250);
          }),
        ]);

        setState('LOADED');

        setTimeout(() => {
          setState('READY');
        }, 2000);
      }

      void copyLinkWithUiFeedback();
    }}>
      <MaterialSymbol
        className={state === 'LOADING' ? 'animate-spin' : ''}
        icon={
          state === 'LOADING'
            ? 'progress_activity'
            : state === 'LOADED'
              ? 'check'
              : 'content_copy'
        }
      />
      {as === 'button' && (state === 'LOADED' ? 'Copied' : 'Copy Link')}
      </Component>
  );
}
