import { ReactNode } from 'react';

export function Card({
  actionItem,
  children,
  className = '',
  id,
  title,
}: {
  actionItem?: ReactNode;
  children: ReactNode;
  className?: string;
  id?: string;
  title?: string;
}) {
  return (
    <div className={`bg-gray-920 md:rounded-md overflow-hidden ${className}`} id={id}>
      {(title !== undefined || actionItem !== undefined) && (
        <div className="flex gap-x-4 bg-gray-900 px-4 sm:px-6 lg:px-8 py-3.5 font-semibold">
          {title !== undefined && (
            <div className="truncate flex-grow">
              {title}
            </div>
          )}
          {actionItem}
        </div>
      )}
      <div className="p-4 sm:p-6 lg:p-8">
        {children}
      </div>
    </div>
  );
}
