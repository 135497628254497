import { Page } from '@/components/Page';

import { QuestionnaireAccessCodesListPageContent } from './QuestionnaireAccessCodesListPageContent';

export function QuestionnaireAccessCodesListPage() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Access Codes"
    >
      <QuestionnaireAccessCodesListPageContent />
    </Page>
  );
}
