import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { useLocationState } from '@/hooks/useLocationState';
import { GetLocationData, Location } from '@/types/api';

import { LocationDetailsPageContent } from './LocationDetailsPageContent';

export function LocationDetailsPage() {
  const params = useParams();

  const { data } = useApi<GetLocationData>({
    path: `/locations/${params.locationPrn}`,
  });

  const locationState = useLocationState<{ location: Location; }>();

  const [location, setLocation] = useState<Location | null>(locationState?.location ?? null);

  useEffect(() => {
    if (data !== undefined) {
      // Bust react-router's cached state and replace it with the fetched data
      setLocation(data.location);
      window.history.replaceState({}, '');
    }
  }, [data]);

  const { currentAccount } = useAppContext();

  if (currentAccount === undefined || location === null) {
    return <PageBodySkeleton />;
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER) {
    return (
      <div className="flex flex-col gap-4">
        <div className="font-display text-purple-200 pl-4 sm:pl-6">{location.name}</div>
        <LocationDetailsPageContent location={location} setLocation={setLocation} />
      </div>
    );
  } else {
    return <LocationDetailsPageWrapper location={location} setLocation={setLocation} />;
  }
}

export function LocationDetailsPageWrapper({
  location,
  setLocation,
}: {
  location: Location;
  setLocation: Dispatch<SetStateAction<Location | null>>;
}) {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Locations',
          path: '/locations'
        },
      ]}
      title={location.name}
    >
      <LocationDetailsPageContent location={location} setLocation={setLocation} />
    </Page>
  );
}
