import { NavLink } from 'react-router-dom';

import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SubNavData } from '@/types/data';

export function SubNavLink({
  icon,
  label,
  path,
}: SubNavData) {
  const useLabel = typeof label === 'string'
    ? label
    : (
      <>
        <span className="sm:hidden">{label.xs}</span>
        <span className="hidden sm:inline">{label.sm}</span>
      </>
    );

  return (
    <NavLink
      className="flex flex-col xl:flex-row items-center justify-center w-[20vw] xl:w-auto h-full py-1 sm:py-2 xl:py-0 overflow-hidden"
      to={path}
    >
      {({ isActive }) => (
        <>
          <MaterialSymbol
            className={`
              ${isActive 
                ? 'bg-gradient-to-b from-purple to-pink text-transparent bg-clip-text'
                : ''
              }
              text-3xl xl:text-2xl sm:mb-1 xl:mb-0 xl:mr-2
            `}
            icon={icon}
          />
          <div className="text-xs sm:text-sm sm:font-semibold whitespace-nowrap">{useLabel}</div>
        </>
      )}
    </NavLink>
  );
}
