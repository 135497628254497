import { createBrowserRouter } from 'react-router-dom';

import { SelectAccountPage } from '@/features/accounts';
import { AppBootstrapper, IndexRedirector } from '@/features/app';
import { DashboardPage } from '@/features/dashboard';
import { EmployerBasePage, EmployersListPage, NewEmployerPage } from '@/features/employers';
import {
  JobRoleDetailsPage,
  JobRolesListPage,
  JobRolesListPageContent,
  NewJobRolePage,
  NewJobRolePageContent,
} from '@/features/job-roles';
import {
  LocationDetailsPage,
  LocationsListPage,
  LocationsListPageContent,
  NewLocationPage,
  NewLocationPageContent,
} from '@/features/locations';
import { ProfilePage } from '@/features/profile';
import {
  NewQuestionnaireAccessCodePage,
  NewQuestionnaireAccessCodePageContent,
  QuestionnaireAccessCodeDetailsPage,
  QuestionnaireAccessCodesListPage,
  QuestionnaireAccessCodesListPageContent,
} from '@/features/questionnaire-access-codes';
import { ReportsPage } from '@/features/reporting';
import {
  EmployerRespiratorsListPage,
  EmployerRespiratorsListPageContent,
  NewEmployerRespiratorPage,
  NewEmployerRespiratorPageContent,
  NewRespiratorFitTestPage,
} from '@/features/respirator-fit-tests';
import {
  NewUserPage,
  NewUserPageContent,
  UsersListPage,
  UsersListPageContent,
} from '@/features/users';

export const router = createBrowserRouter([
  {
    element: <AppBootstrapper />,
    children: [
      {
        path: '/',
        children: [
          {
            index: true,
            element: <IndexRedirector />,
          },
          {
            path: 'dashboard',
            element: <DashboardPage />,
          },
          {
            path: 'employers',
            children: [
              {
                index: true,
                element: <EmployersListPage />,
              },
              {
                path: 'new',
                element: <NewEmployerPage />,
              },
              {
                path: ':employerPrn',
                element: <EmployerBasePage />,
                children: [
                  // {
                  //   index: true,
                  //   element: <EmployerSettingsPage />
                  // },
                  // {
                  //   path: 'employees',
                  //   element: <EmployeesListPage />,
                  // },
                  {
                    path: 'employer-respirators',
                    children: [
                      {
                        index: true,
                        element: <EmployerRespiratorsListPageContent />,
                      },
                      {
                        path: 'new',
                        element: <NewEmployerRespiratorPageContent />,
                      },
                    ],
                  },
                  {
                    path: 'questionnaire-access-codes',
                    children: [
                      {
                        index: true,
                        element: <QuestionnaireAccessCodesListPageContent />,
                      },
                      {
                        path: 'new',
                        element: <NewQuestionnaireAccessCodePageContent />,
                      },
                      {
                        path: ':questionnaireAccessCodePrn',
                        element: <QuestionnaireAccessCodeDetailsPage />,
                      },
                    ],
                  },
                  {
                    path: 'job-roles',
                    children: [
                      {
                        index: true,
                        element: <JobRolesListPageContent />,
                      },
                      {
                        path: 'new',
                        element: <NewJobRolePageContent />,
                      },
                      {
                        path: ':jobRolePrn',
                        element: <JobRoleDetailsPage />
                      },
                    ],
                  },
                  {
                    path: 'locations',
                    children: [
                      {
                        index: true,
                        element: <LocationsListPageContent />,
                      },
                      {
                        path: 'new',
                        element: <NewLocationPageContent />,
                      },
                      {
                        path: ':locationPrn',
                        element: <LocationDetailsPage />,
                      },
                    ],
                  },
                  {
                    path: 'users',
                    children: [
                      {
                        index: true,
                        element: <UsersListPageContent />,
                      },
                      {
                        path: 'new',
                        element: <NewUserPageContent />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'employer-respirators',
            children: [
              {
                index: true,
                element: <EmployerRespiratorsListPage />,
              },
              {
                path: 'new',
                element: <NewEmployerRespiratorPage />,
              },
            ],
          },
          {
            path: 'job-roles',
            children: [
              {
                index: true,
                element: <JobRolesListPage />,
              },
              {
                path: 'new',
                element: <NewJobRolePage />,
              },
              {
                path: ':jobRolePrn',
                element: <JobRoleDetailsPage />,
              },
            ],
          },
          {
            path: 'locations',
            children: [
              {
                index: true,
                element: <LocationsListPage />,
              },
              {
                path: 'new',
                element: <NewLocationPage />,
              },
              {
                path: ':locationPrn',
                element: <LocationDetailsPage />,
              },
            ],
          },
          {
            path: 'profile',
            element: <ProfilePage />,
          },
          {
            path: 'questionnaire-access-codes',
            children: [
              {
                index: true,
                element: <QuestionnaireAccessCodesListPage />,
              },
              {
                path: 'new',
                element: <NewQuestionnaireAccessCodePage />,
              },
              {
                path: ':questionnaireAccessCodePrn',
                element: <QuestionnaireAccessCodeDetailsPage />,
              },
            ],
          },
          {
            path: 'reports',
            element: <ReportsPage />,
          },
          {
            path: 'respirator-fit-tests/new',
            element: <NewRespiratorFitTestPage />,
          },
          {
            path: 'select-account',
            element: <SelectAccountPage />,
          },
          {
            path: 'users',
            children: [
              {
                index: true,
                element: <UsersListPage />,
              },
              {
                path: 'new',
                element: <NewUserPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
