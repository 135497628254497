import { useEffect, useId, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { CheckboxInput } from '@/components/CheckboxInput';
import { ErrorBanner } from '@/components/ErrorBanner';
import { FormGroup } from '@/components/FormGroup';
import { Page } from '@/components/Page';
import { TextInput } from '@/components/TextInput';
import { useAppContext, useAuthContext } from '@/features/app';
import { CreateEmployerRequestData, CreateEmployerResponseData } from '@/types/api';
import { scrollToClass } from '@/utils/dom';
import { ValidationError } from '@/utils/validation';

import { CreateEmployerFormData, CreateEmployerFormErrors } from '../types';

export function NewEmployerPage() {
  const createAdminId = useId();

  const [formData, setFormData] = useState<CreateEmployerFormData>({
    name: '',
    userEmailAddress: '',
    userFirstName: '',
    userLastName: '',
  });

  const [validationErrors, setValidationErrors] = useState<CreateEmployerFormErrors>({});

  const [submitState, setSubmitState] = useState<
    'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS'
  >('UNSUBMITTED');

  const [createAdmin, setCreateAdmin] = useState(false);

  const { currentAccount } = useAppContext();
  const { getAccessToken } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!createAdmin) {
      setFormData((formData) => ({
        ...formData,
        userEmailAddress: '',
        userFirstName: '',
        userLastName: '',
      }));
    }
  }, [createAdmin]);

  function validate(): CreateEmployerRequestData {
    if (currentAccount === undefined) {
      throw new Error();
    }

    const requestData: Partial<CreateEmployerRequestData> = {
      serviceProvider: {
        prn: currentAccount.prn,
      },
    };

    const newErrors: CreateEmployerFormErrors = {};

    if (formData.name.trim().length > 0) {
      requestData.name = formData.name;
    } else {
      newErrors.name = 'This field is required.';
    }

    if (createAdmin) {
      const user: Partial<CreateEmployerRequestData['user']> = {};

      if (formData.userEmailAddress.trim().length > 0) {
        if (/^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i.test(formData.userEmailAddress)) {
          user.emailAddress = formData.userEmailAddress;
        } else {
          newErrors.userEmailAddress = 'This field is invalid.';
        }
      } else {
        newErrors.userEmailAddress = 'This field is required.';
      }

      if (formData.userFirstName.trim().length > 0) {
        user.firstName = formData.userFirstName;
      } else {
        newErrors.userFirstName = 'This field is required.';
      }

      if (formData.userLastName.trim().length > 0) {
        user.lastName = formData.userLastName;
      } else {
        newErrors.userLastName = 'This field is required.';
      }

      requestData.user = user as CreateEmployerRequestData['user'];
    } else {
      requestData.user = null;
    }

    if (Object.keys(newErrors).length > 0) {
      throw new ValidationError(newErrors);
    }

    return requestData as CreateEmployerRequestData;
  }

  async function submit() {
    const accessToken = await getAccessToken();

    let requestData: CreateEmployerRequestData;

    try {
      requestData = validate();
      setValidationErrors({});
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        // Unexpected error type
        throw err;
      }

      setValidationErrors(err.errors as CreateEmployerFormErrors);
      scrollToClass('form-error');

      return;
    }

    setSubmitState('SUBMITTING');

    try {
      const result = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/temp-employers`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          method: 'POST',
          body: JSON.stringify(requestData),
        },
      );

      if (result.status !== 201) {
        throw new Error();
      }

      const resultJson = await result.json() as CreateEmployerResponseData;

      setSubmitState('SUCCESS');

      // TODO | Navigate to new employer
      navigate(`/employers/${resultJson.employer.prn}/locations`);
    } catch (err) {
      setSubmitState('ERROR');
      scrollToClass('error-banner');
    }
  }

  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Employers',
          path: '/employers'
        },
      ]}
      title="Add Employer"
    >
      <div className="flex flex-col gap-4">
        <Card title="Employer Details">
          <div className="max-w-2xl mx-auto">
            <div className="flex flex-col gap-y-10">
              <FormGroup error={validationErrors.name} label="Employer Name">
                {() => (
                  <TextInput
                    className="grow"
                    maxLength={128}
                    onChange={(evt) => {
                      setFormData({
                        ...formData,
                        name: evt.target.value,
                      });
                    }}
                    value={formData.name}
                  />
                )}
              </FormGroup>
            </div>
          </div>
        </Card>
        <Card title="Employer Admin User">
          <div className="max-w-2xl mx-auto">
            <div className="flex flex-col gap-y-10">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <CheckboxInput
                    checked={createAdmin}
                    id={createAdminId}
                    onChange={(createAdmin) => {
                      setCreateAdmin(createAdmin);
                    }}
                  />
                </div>
                <div className="ml-3 leading-6">
                  <label
                    className="cursor-pointer"
                    htmlFor={createAdminId}
                  >
                    Create an admin user for this employer
                  </label>
                </div>
              </div>
              {createAdmin && (
                <>
                  <FormGroup error={validationErrors.userFirstName} label="Admin First Name">
                    {() => (
                      <TextInput
                        className="grow"
                        maxLength={64}
                        onChange={(evt) => {
                          setFormData({
                            ...formData,
                            userFirstName: evt.target.value,
                          });
                        }}
                        value={formData.userFirstName}
                      />
                    )}
                  </FormGroup>
                  <FormGroup error={validationErrors.userLastName} label="Admin Last Name">
                    {() => (
                      <TextInput
                        className="grow"
                        maxLength={64}
                        onChange={(evt) => {
                          setFormData({
                            ...formData,
                            userLastName: evt.target.value,
                          });
                        }}
                        value={formData.userLastName}
                      />
                    )}
                  </FormGroup>
                  <FormGroup error={validationErrors.userEmailAddress} label="Admin Email Address">
                    {() => (
                      <TextInput
                        className="grow"
                        maxLength={128}
                        onChange={(evt) => {
                          setFormData({
                            ...formData,
                            userEmailAddress: evt.target.value,
                          });
                        }}
                        value={formData.userEmailAddress}
                      />
                    )}
                  </FormGroup>
                </>
              )}
            </div>
          </div>
        </Card>
        <div className="flex flex-col gap-y-4">
          {submitState === 'ERROR' && (
            <ErrorBanner message="Could not create employer. Please check your internet connection and try again." />
          )}
          <div className="flex justify-center">
            <Button
              disabled={submitState === 'SUBMITTING'}
              loading={submitState === 'SUBMITTING'}
              onClick={() => {
                void submit();
              }}
              size="lg"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Page>
  );
}
