import { ReactNode } from 'react';

export function TableBody({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <tbody>
      {children}
    </tbody>
  );
}
