import { Page } from '@/components/Page';

import { EmployerRespiratorsListPageContent } from './EmployerRespiratorsListPageContent';

export function EmployerRespiratorsListPage() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Respirators"
    >
      <EmployerRespiratorsListPageContent />
    </Page>
  );
}
