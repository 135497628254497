import { SubNavData } from '@/types/data';

import { SubNavLink } from './SubNavLink';

export function SubNav({
  items,
}: {
  items: SubNavData[];
}) {
  return (
    <div className="flex h-16 sm:h-20 xl:h-16 mt-4 xl:px-8 xl:gap-x-8 bg-gray-960 border-y xl:border-l border-gray-900">
      {items.map(({ icon, label, path }) => (
        <SubNavLink icon={icon} key={path} label={label} path={path} />
      ))}
    </div>
  );
}
