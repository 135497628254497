export enum RespiratorFitTestProtocol {
  QUALITATIVE_BITREX_SOLUTION_AEROSOL = 'QUALITATIVE_BITREX_SOLUTION_AEROSOL',
  QUALITATIVE_IRRITANT_SMOKE = 'QUALITATIVE_IRRITANT_SMOKE',
  QUALITATIVE_ISOAMYL_ACETATE = 'QUALITATIVE_ISOAMYL_ACETATE',
  QUALITATIVE_SACCHARIN_SOLUTION_AEROSOL = 'QUALITATIVE_SACCHARIN_SOLUTION_AEROSOL',
  QUANTITATIVE = 'QUANTITATIVE',
}

export enum RespiratorFitTestResult {
  FAIL = 'FAIL',
  PASS = 'PASS',
}
