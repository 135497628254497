import { Page } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableRow,
} from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { RecordSubtype } from '@/features/reporting';
import { RespiratorMedicalEvaluationStatus } from '@/features/respirator-medical-evaluations';
import { useApi } from '@/hooks/useApi';
import { ProximaRecord, QueryApiResponseData } from '@/types/api';
import { NAME_SUFFIX_LABELS } from '@/utils/pii';

const rmeStatusLabels = {
  [RespiratorMedicalEvaluationStatus.APPROVED]: 'Approved',
  [RespiratorMedicalEvaluationStatus.DEFERRED]: 'Deferred',
  [RespiratorMedicalEvaluationStatus.EXPIRED_INTERVIEW]: 'Expired',
  [RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED]: 'Partially Approved',
  [RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW]: 'Interview Needed',
  [RespiratorMedicalEvaluationStatus.PENDING_REVIEW]: 'In Progress',
};

export function DashboardPage() {
  const { currentAccount } = useAppContext();

  const { data, failed, isLoading } = useApi<QueryApiResponseData<ProximaRecord>>({
    disable: currentAccount === undefined,
    path: `/records?${
      currentAccount?.accountType === AccountType.SERVICE_PROVIDER
        ? 'serviceProviderPrn'
        : 'employerPrn'
    }=${
      currentAccount?.prn
    }&respiratorMedicalEvaluationStatus=PENDING_REVIEW,PENDING_INTERVIEW`,
  });

  const hour = (new Date()).getHours();

  let message: string;

  if (hour < 3 || hour >= 18) {
    message = 'Good evening';
  } else if (hour >= 3 && hour < 12) {
    message = 'Good morning';
  } else {
    message = 'Good afternoon';
  }

  return (
    <Page title={`${message}! Welcome to Proxima.`}>
      {failed ? null : isLoading ? (
        <PageBodySkeleton />
      ) : data.data.length > 0 && (
        <div className="flex flex-col gap-y-3">
          <div className="font-display text-purple-200 pl-4 sm:pl-6">CLEARANCES IN PROGRESS</div>
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <Table>
                <TableHeader>
                  <TableHeaderRow>
                    <TableHeaderCell firstCol>Name</TableHeaderCell>
                    <TableHeaderCell>Job Role</TableHeaderCell>
                    <TableHeaderCell>Location</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                  </TableHeaderRow>
                </TableHeader>
                <TableBody>
                  {data.data.map((record) => (
                    <TableRow key={record.prn}>
                      <TableCell firstCol header>
                        {record.employee.lastName}
                        ,{' '}
                        {record.employee.firstName}
                        {record.employee.nameSuffix !== null && (
                          ` ${NAME_SUFFIX_LABELS[record.employee.nameSuffix]}`
                        )}
                      </TableCell>
                      <TableCell>
                        {record.jobRole.name}
                      </TableCell>
                      <TableCell>
                        {record.location.name}
                      </TableCell>
                      <TableCell>
                        {record.subtype === RecordSubtype.RESPIRATOR_MEDICAL_EVALUATION &&
                          rmeStatusLabels[record.status]
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </Page>
  );
}
