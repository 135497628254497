import { ReactNode, useId } from 'react';

import { FormLabel } from './FormLabel';
import { FormError } from './FormError';

export function FormGroup({
  children,
  error,
  label,
  sublabel,
}: {
  children: (props: { id: string; }) => ReactNode;
  error?: string;
  label: string;
  sublabel?: string;
}) {
  const id = useId();

  return (
    <div className="flex flex-col gap-y-3">
      {error !== undefined && (
        <FormError error={error} />
      )}
      <div>
        <FormLabel htmlFor={id}>{label}</FormLabel>
        {sublabel !== undefined && (
          <div className="text-sm text-gray-400">{sublabel}</div>
        )}
      </div>
      {children({ id })}
    </div>
  );
}
