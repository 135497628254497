import { ReactNode } from 'react';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { BreadcrumbData, SubNavData } from '@/types/data';

import { SubNav } from '../SubNav';

import { PagePadder } from './PagePadder';

export function Page({
  breadcrumbs,
  children,
  subNav,
  title,
}: {
  breadcrumbs?: BreadcrumbData[];
  children: ReactNode;
  subNav?: SubNavData[];
  title: string;
}) {
  return (
    <div className="flex flex-col gap-y-6 lg:gap-y-10">
      <div>
        {breadcrumbs !== undefined && (
          <div className="px-4 sm:px-6 lg:px-8">
            <Breadcrumbs items={breadcrumbs} />
          </div>
        )}
        <div
          className={`
            px-4 sm:px-6 lg:px-8 font-display font-semibold text-2xl
          `}
        >
          {title}
        </div>
        {subNav !== undefined && (
          <SubNav items={subNav} />
        )}
      </div>
      <PagePadder>
        {children}
      </PagePadder>
    </div>
  );
}
