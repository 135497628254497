import { Page } from '@/components/Page';

import {
  NewQuestionnaireAccessCodePageContent,
} from './NewQuestionnaireAccessCodePageContent';

export function NewQuestionnaireAccessCodePage() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Access Codes',
          path: '/questionnaire-access-codes'
        },
      ]}
      title="Add Access Code"
    >
      <NewQuestionnaireAccessCodePageContent />
    </Page>
  );
}
