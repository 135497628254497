import { MaterialSymbol } from './MaterialSymbol';

export function FormError({
  error,
}: {
  error: string;
}) {
  return (
    <div className="form-error flex items-start gap-2 text-yellow font-semibold">
      <MaterialSymbol icon="warning" />
      {error}
    </div>
  );
}
