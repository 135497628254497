import { useState } from 'react';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Page } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { useAppContext, useAuthContext } from '@/features/app';
import { AccountUserAssociationResponseData } from '@/types/api';

export function ProfilePage() {
  const [toggleNotificationsState, setToggleNotificationsState] = useState<
    'READY' | 'SUBMITTING' | 'ERROR'
  >('READY');

  const { currentAccount, setCurrentAccount } = useAppContext();
  const { getAccessToken } = useAuthContext();

  return currentAccount === undefined ? (
    <PageBodySkeleton />
  ) : (
    <Page title="My Profile">
      <Card>
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col gap-y-3">
            <div className="text-sm text-purple-200 font-semibold uppercase">Notifications</div>
            <div className="flex flex-col gap-y-3 items-start">
              <div>
                You are{' '}
                {!currentAccount.notificationsEnabled && 'not '}
                currently receiving notifications for {currentAccount.preferredName}.
              </div>
              <Button
                disabled={toggleNotificationsState === 'SUBMITTING'}
                onClick={() => {
                  async function toggleEnabled() {
                    try {
                      if (currentAccount === undefined) {
                        throw new Error();
                      }

                      setToggleNotificationsState('SUBMITTING');

                      const accessToken = await getAccessToken();

                      const response = await fetch(
                        `${import.meta.env.VITE_API_BASE_URL}/account-user-associations/${currentAccount.associationPrn}`,
                        {
                          headers: {
                            authorization: `Bearer ${accessToken}`,
                          },
                          method: 'PUT',
                          body: JSON.stringify({
                            prn: currentAccount.associationPrn,
                            notificationsEnabled: !currentAccount.notificationsEnabled,
                          }),
                        },
                      );

                      if (response.status !== 200) {
                        throw new Error();
                      }

                      const resultJson = await response.json() as AccountUserAssociationResponseData;

                      setCurrentAccount({
                        ...currentAccount,
                        notificationsEnabled: resultJson.accountUserAssociation.notificationsEnabled,
                      });

                      setToggleNotificationsState('READY');
                    } catch (err) {
                      setToggleNotificationsState('ERROR');
                    }
                  }

                  void toggleEnabled();
                }}
              >
                <MaterialSymbol
                  className={toggleNotificationsState === 'SUBMITTING' ? 'animate-spin' : ''}
                  icon={toggleNotificationsState === 'SUBMITTING' ? 'cached' : currentAccount.notificationsEnabled ? 'block' : 'bolt'}
                />
                {currentAccount.notificationsEnabled ? 'Disable' : 'Enable'} Notifications
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </Page>
  );
}
