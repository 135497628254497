import { RespiratorType } from './enums';

export const RESPIRATOR_TYPE_LONG_LABELS = {
  [RespiratorType.AIR_PURIFYING_DISPOSABLE]: 'Disposable (N95, P100, etc.)',
  [RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE]: 'Full-Face Air Purifying (APR)',
  [RespiratorType.AIR_PURIFYING_REUSABLE_PARTIAL_FACE]: 'Partial-Face Air Purifying (APR)',
  [RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE]: 'Full-Face Powered Air Purifying (PAPR)',
  [RespiratorType.POWERED_AIR_PURIFYING_PARTIAL_FACE]: 'Partial-Face Powered Air Purifying (PAPR)',
  [RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE]: 'Full-Face Supplied Air (SAR)',
  [RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE]: 'Partial-Face Supplied Air (SAR)',
  [RespiratorType.SUPPLIED_AIR_SCBA]: 'SCBA',
};

export const RESPIRATOR_TYPE_SHORT_LABELS = {
  [RespiratorType.AIR_PURIFYING_DISPOSABLE]: 'Disposable',
  [RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE]: 'APR (Full)',
  [RespiratorType.AIR_PURIFYING_REUSABLE_PARTIAL_FACE]: 'APR (Partial)',
  [RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE]: 'PAPR (Full)',
  [RespiratorType.POWERED_AIR_PURIFYING_PARTIAL_FACE]: 'PAPR (Partial)',
  [RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE]: 'SAR (Full)',
  [RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE]: 'SAR (Partial)',
  [RespiratorType.SUPPLIED_AIR_SCBA]: 'SCBA',
};

export const ORDERED_RESPIRATOR_TYPE_OPTIONS = [
  RespiratorType.AIR_PURIFYING_DISPOSABLE,
  RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE,
  RespiratorType.AIR_PURIFYING_REUSABLE_PARTIAL_FACE,
  RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE,
  RespiratorType.POWERED_AIR_PURIFYING_PARTIAL_FACE,
  RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE,
  RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE,
  RespiratorType.SUPPLIED_AIR_SCBA,
];

export const RESPIRATOR_TYPE_OPTIONS = ORDERED_RESPIRATOR_TYPE_OPTIONS.map(
  (respiratorType) => ({
    key: respiratorType,
    label: RESPIRATOR_TYPE_LONG_LABELS[respiratorType],
    value: respiratorType,
  }),
);
