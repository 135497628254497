import { Dispatch, SetStateAction, useState } from 'react';

import { Card } from '@/components/Card';
import { Button } from '@/components/Button';
import { ErrorBanner } from '@/components/ErrorBanner';
import { FormGroup } from '@/components/FormGroup';
import { SuccessBanner } from '@/components/SuccessBanner';
import { TextInput } from '@/components/TextInput';
import { useAppContext, useAuthContext } from '@/features/app';
import { GetLocationData, Location, UpdateLocationRequestData } from '@/types/api';
import { scrollToClass } from '@/utils/dom';
import { ValidationError } from '@/utils/validation';

import { UpdateLocationFormData, UpdateLocationFormErrors } from '../types';

export function LocationDetailsPageContent({
  location,
  setLocation,
}: {
  location: Location;
  setLocation: Dispatch<SetStateAction<Location | null>>;
}) {
  const [formData, setFormData] = useState<UpdateLocationFormData>({
    name: location.name,
  });

  const [validationErrors, setValidationErrors] = useState<UpdateLocationFormErrors>({});

  const [submitState, setSubmitState] = useState<
    'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS'
  >('UNSUBMITTED');

  const { currentEmployerPrn } = useAppContext();
  const { getAccessToken } = useAuthContext();

  function validate(): UpdateLocationRequestData {
    if (currentEmployerPrn === undefined || currentEmployerPrn === null) {
      throw new Error();
    }

    const requestData: Partial<UpdateLocationRequestData> = {
      prn: location.prn,
    };

    const newErrors: UpdateLocationFormErrors = {};

    if (formData.name.trim().length > 0) {
      requestData.name = formData.name;
    } else {
      newErrors.name = 'This field is required.';
    }

    if (Object.keys(newErrors).length > 0) {
      throw new ValidationError(newErrors);
    }

    return requestData as UpdateLocationRequestData;
  }

  async function submit() {
    const accessToken = await getAccessToken();

    let requestData: UpdateLocationRequestData;

    try {
      requestData = validate();
      setValidationErrors({});
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        // Unexpected error type
        throw err;
      }

      setValidationErrors(err.errors as UpdateLocationFormErrors);
      scrollToClass('form-error');

      return;
    }

    setSubmitState('SUBMITTING');

    try {
      const result = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/locations/${location.prn}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          method: 'PUT',
          body: JSON.stringify(requestData),
        },
      );

      if (result.status !== 200) {
        throw new Error();
      }

      const data = await result.json() as GetLocationData;

      setLocation(data.location);
      setSubmitState('SUCCESS');
    } catch (err) {
      setSubmitState('ERROR');
      scrollToClass('error-banner');
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <Card title="Location Details">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col gap-y-10">
            <FormGroup error={validationErrors.name} label="Location Name">
              {() => (
                <TextInput
                  className="grow"
                  maxLength={128}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      name: evt.target.value,
                    });
                  }}
                  value={formData.name}
                />
              )}
            </FormGroup>
          </div>
        </div>
      </Card>
      {submitState === 'SUCCESS' && (
        <SuccessBanner message="Changes to location successfully saved." />
      )}
      {submitState === 'ERROR' && (
        <ErrorBanner message="Could not save location. Please check your internet connection and try again." />
      )}
      <div className="flex justify-center">
        <Button
          disabled={submitState === 'SUBMITTING'}
          loading={submitState === 'SUBMITTING'}
          onClick={() => {
            void submit();
          }}
          size="lg"
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}
