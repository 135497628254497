import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { ErrorBanner } from '@/components/ErrorBanner';
import { FormGroup } from '@/components/FormGroup';
import { TextInput } from '@/components/TextInput';
import { AccountType } from '@/features/accounts';
import { useAppContext, useAuthContext } from '@/features/app';
import { CreateUserRequestData } from '@/types/api';
import { scrollToClass } from '@/utils/dom';
import { ValidationError } from '@/utils/validation';

import { CreateUserFormData, CreateUserFormErrors } from '../types';

export function NewUserPageContent() {
  const [formData, setFormData] = useState<CreateUserFormData>({
    emailAddress: '',
    firstName: '',
    lastName: '',
  });

  const [validationErrors, setValidationErrors] = useState<CreateUserFormErrors>({});

  const [submitState, setSubmitState] = useState<
    'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS'
  >('UNSUBMITTED');

  const { currentAccount, currentEmployerPrn } = useAppContext();
  const { getAccessToken } = useAuthContext();

  const navigate = useNavigate();

  function validate(): CreateUserRequestData {
    if (currentAccount === undefined) {
      throw new Error();
    }

    const requestData: Partial<CreateUserRequestData> = {
      account: {
        prn: currentEmployerPrn ?? currentAccount.prn,
      },
    };

    const newErrors: CreateUserFormErrors = {};

    if (formData.emailAddress.trim().length > 0) {
      if (/^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i.test(formData.emailAddress)) {
        requestData.emailAddress = formData.emailAddress;
      } else {
        newErrors.emailAddress = 'This field is invalid.';
      }
    } else {
      newErrors.emailAddress = 'This field is required.';
    }

    if (formData.firstName.trim().length > 0) {
      requestData.firstName = formData.firstName;
    } else {
      newErrors.firstName = 'This field is required.';
    }

    if (formData.lastName.trim().length > 0) {
      requestData.lastName = formData.lastName;
    } else {
      newErrors.lastName = 'This field is required.';
    }

    if (Object.keys(newErrors).length > 0) {
      throw new ValidationError(newErrors);
    }

    return requestData as CreateUserRequestData;
  }

  async function submit() {
    const accessToken = await getAccessToken();

    let requestData: CreateUserRequestData;

    try {
      requestData = validate();
      setValidationErrors({});
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        // Unexpected error type
        throw err;
      }

      setValidationErrors(err.errors as CreateUserFormErrors);
      scrollToClass('form-error');

      return;
    }

    setSubmitState('SUBMITTING');

    try {
      const result = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/temp-account-users`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          method: 'POST',
          body: JSON.stringify(requestData),
        },
      );

      if (result.status !== 201) {
        throw new Error();
      }

      setSubmitState('SUCCESS');

      // TODO | Navigate to new location
      navigate(
        currentAccount?.accountType === AccountType.SERVICE_PROVIDER
          ? `/employers/${currentEmployerPrn}/users`
          : '/users',
      );
    } catch (err) {
      setSubmitState('ERROR');
      scrollToClass('error-banner');
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="font-display text-purple-200 pl-4 sm:pl-6">Add New User</div>
      <Card title="User Details">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col gap-y-10">
            <FormGroup error={validationErrors.firstName} label="First Name">
              {() => (
                <TextInput
                  className="grow"
                  maxLength={64}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      firstName: evt.target.value,
                    });
                  }}
                  value={formData.firstName}
                />
              )}
            </FormGroup>
            <FormGroup error={validationErrors.lastName} label="Last Name">
              {() => (
                <TextInput
                  className="grow"
                  maxLength={64}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      lastName: evt.target.value,
                    });
                  }}
                  value={formData.lastName}
                />
              )}
            </FormGroup>
            <FormGroup error={validationErrors.emailAddress} label="Email Address">
              {() => (
                <TextInput
                  className="grow"
                  maxLength={128}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      emailAddress: evt.target.value,
                    });
                  }}
                  value={formData.emailAddress}
                />
              )}
            </FormGroup>
          </div>
        </div>
      </Card>
      <div className="flex flex-col gap-y-4">
        {submitState === 'ERROR' && (
          <ErrorBanner message="Could not create user. Please check your internet connection and try again." />
        )}
        <div className="flex justify-center">
          <Button
            disabled={submitState === 'SUBMITTING'}
            loading={submitState === 'SUBMITTING'}
            onClick={() => {
              void submit();
            }}
            size="lg"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
