import { MaterialSymbol } from '@/components/MaterialSymbol';

export function ErrorBanner({
  message,
}: {
  message: string;
}) {
  return (
    <div className="error-banner md:rounded-md bg-yellow-300 p-4 sm:px-6 text-yellow-900 flex items-center gap-x-4">
      <MaterialSymbol className="text-3xl font-semibold" icon="warning" />
      <div className="flex flex-col items-start">
        <div className="text-sm font-semibold">ERROR</div>
        <div>{message}</div>
      </div>
    </div>
  );
}
