import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card } from '@/components/Card';
import { Button } from '@/components/Button';
import { ErrorBanner } from '@/components/ErrorBanner';
import { FormGroup } from '@/components/FormGroup';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { SelectInput } from '@/components/SelectInput';
import { TextInput } from '@/components/TextInput';
import { AccountType } from '@/features/accounts';
import { useAppContext, useAuthContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import {
  CreateQuestionnaireAccessCodeRequestData,
  JobRole,
  Location,
  QueryApiResponseData,
} from '@/types/api';
import { scrollToClass } from '@/utils/dom';
import { ValidationError } from '@/utils/validation';

import {
  CreateQuestionnaireAccessCodeFormData,
  CreateQuestionnaireAccessCodeFormErrors,
} from '../types';

export function NewQuestionnaireAccessCodePageContent() {
  const [formData, setFormData] = useState<CreateQuestionnaireAccessCodeFormData>({
    allowAllLocations: null,
    jobRole: null,
    locations: [],
    name: '',
  });

  const [validationErrors, setValidationErrors] = useState<
    CreateQuestionnaireAccessCodeFormErrors
  >({});

  const [submitState, setSubmitState] = useState<
    'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS'
  >('UNSUBMITTED');

  const { currentAccount, currentEmployerPrn } = useAppContext();
  const { getAccessToken } = useAuthContext();

  const navigate = useNavigate();

  const {
    data: jobRolesQuery,
    failed: jobRolesFailed,
    isLoading: jobRolesIsLoading,
  } = useApi<QueryApiResponseData<JobRole>>({
    disable: (currentEmployerPrn ?? null) === null,
    path: `/job-roles?employerPrn=${currentEmployerPrn}&sortBy=name`
  });

  const {
    data: locationsQuery,
    failed: locationsFailed,
    isLoading: locationsIsLoading,
  } = useApi<QueryApiResponseData<Location>>({
    disable: (currentEmployerPrn ?? null) === null,
    path: `/locations?employerPrn=${currentEmployerPrn}&sortBy=name`
  });

  function validate(): CreateQuestionnaireAccessCodeRequestData {
    if (currentAccount === undefined || currentEmployerPrn === null) {
      throw new Error();
    }

    let requestData: Partial<CreateQuestionnaireAccessCodeRequestData> = {};

    const newErrors: CreateQuestionnaireAccessCodeFormErrors = {};

    if (formData.jobRole !== null) {
      requestData.jobRole = {
        prn: formData.jobRole.prn,
      };
    } else {
      newErrors.jobRole = 'This field is required.';
    }

    if (formData.allowAllLocations !== null) {
      if (formData.allowAllLocations) {
        requestData.allowAllLocations = true;
      } else {
        if (formData.locations.length > 0) {
          requestData = {
            ...requestData,
            allowAllLocations: false,
            locations: formData.locations.map((formLocation) => ({
              prn: formLocation.prn,
            })),
          };
        } else {
          newErrors.locations = 'You must select at least one location.';
        }
      }
    } else {
      newErrors.locations = 'This field is required.';
    }

    if (formData.name.trim().length > 0) {
      requestData.name = formData.name;
    } else {
      newErrors.name = 'This field is required.';
    }

    if (currentAccount.accountType === 'SERVICE_PROVIDER') {
      requestData.serviceProvider = {
        prn: currentAccount.prn,
      };
    } else {
      requestData.serviceProvider = null;
    }

    if (Object.keys(newErrors).length > 0) {
      throw new ValidationError(newErrors);
    }

    return requestData as CreateQuestionnaireAccessCodeRequestData;
  }

  async function submit() {
    const accessToken = await getAccessToken();

    let requestData: CreateQuestionnaireAccessCodeRequestData;

    try {
      requestData = validate();
      setValidationErrors({});
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        // Unexpected error type
        throw err;
      }

      setValidationErrors(err.errors as CreateQuestionnaireAccessCodeFormErrors);
      scrollToClass('form-error');

      return;
    }

    setSubmitState('SUBMITTING');

    try {
      const result = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/questionnaire-access-codes`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          method: 'POST',
          body: JSON.stringify(requestData),
        },
      );

      if (result.status !== 201) {
        throw new Error();
      }

      setSubmitState('SUCCESS');

      // TODO | Navigate to new access code
      navigate(
        currentAccount?.accountType === AccountType.SERVICE_PROVIDER
          ? `/employers/${currentEmployerPrn}/questionnaire-access-codes`
          : '/questionnaire-access-codes',
      );
    } catch (err) {
      setSubmitState('ERROR');
      scrollToClass('error-banner');
    }
  }

  return (jobRolesIsLoading || jobRolesFailed || locationsIsLoading || locationsFailed) ? (
    <PageBodySkeleton />
  ) : jobRolesQuery.data.length === 0 || locationsQuery.data.length === 0 ? (
    <ErrorBanner message="You must set up at least one location and one job role before an access code can be created." />
  ) : (
    <div className="flex flex-col gap-4">
      <div className="font-display text-purple-200 pl-4 sm:pl-6">Add New Access Code</div>
      <Card title="Access Code Details">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col gap-y-10">
            <FormGroup error={validationErrors.name} label="Access Code Name">
              {() => (
                <TextInput
                  className="grow"
                  maxLength={128}
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      name: evt.target.value,
                    });
                  }}
                  value={formData.name}
                />
              )}
            </FormGroup>
            <FormGroup error={validationErrors.jobRole} label="Job Role">
              {({ id }) => (
                <SelectInput<JobRole>
                  id={id}
                  onChange={(jobRole) => {
                    setFormData({
                      ...formData,
                      jobRole,
                    });
                  }}
                  options={jobRolesQuery.data.map((jobRole) => ({
                    key: jobRole.prn,
                    label: jobRole.name,
                    value: jobRole,
                  }))}
                  value={formData.jobRole}
                />
              )}
            </FormGroup>
            <FormGroup
              error={validationErrors.locations}
              label="Location(s)"
              sublabel="Select which work location(s) the employee should be allowed to choose from when completing their questionnaire."
            >
              {() => (
                <>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <input
                        checked={formData.allowAllLocations === true}
                        className="h-4 w-4 border-gray-300 text-purple-600 focus:ring-purple-600 cursor-pointer"
                        id="allowAllLocations-true"
                        onChange={(evt) => {
                          setFormData({
                            ...formData,
                            allowAllLocations: evt.target.checked,
                          })
                        }}
                        type="radio"
                      />
                      <label
                        className="ml-3 block leading-6 cursor-pointer"
                        htmlFor="allowAllLocations-true"
                      >
                        All Locations
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        checked={formData.allowAllLocations === false}
                        className="h-4 w-4 border-gray-300 text-purple-600 focus:ring-purple-600 cursor-pointer"
                        id="allowAllLocations-false"
                        onChange={(evt) => {
                          setFormData({
                            ...formData,
                            allowAllLocations: !evt.target.checked,
                          })
                        }}
                        type="radio"
                      />
                      <label
                        className="ml-3 block leading-6 cursor-pointer"
                        htmlFor="allowAllLocations-false"
                      >
                        Specific Locations:
                      </label>
                    </div>
                  </div>
                  <div className="ml-6 space-y-2">
                    {locationsQuery.data.map((location) => (
                      <div className="flex items-center" key={location.prn}>
                        <input
                          checked={(
                            formData.allowAllLocations === true ||
                            formData.locations.find(({ prn }) => prn === location.prn) !== undefined
                          )}
                          className={`
                            h-4 w-4 rounded border-gray-300 text-purple-500 focus:ring-purple-600
                            ${formData.allowAllLocations
                              ? 'cursor-not-allowed opacity-25'
                              : 'cursor-pointer'
                            }
                          `}
                          disabled={formData.allowAllLocations === true}
                          id={`locations-${location.prn}`}
                          onChange={(evt) => {
                            if (evt.target.checked) {
                              setFormData({
                                ...formData,
                                locations: [
                                  ...formData.locations,
                                  location,
                                ],
                              });
                            } else {
                              setFormData({
                                ...formData,
                                locations: formData.locations.filter(
                                  (formLocation) => formLocation.prn !== location.prn,
                                ),
                              });
                            }
                          }}
                          type="checkbox"
                        />
                        <label
                          className={`
                            ml-3 block leading-6
                            ${formData.allowAllLocations
                              ? 'cursor-not-allowed opacity-50'
                              : 'cursor-pointer'
                            }
                          `}
                          htmlFor={`locations-${location.prn}`}
                        >
                          {location.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </FormGroup>
          </div>
        </div>
      </Card>
      <div className="flex flex-col gap-y-4">
        {submitState === 'ERROR' && (
          <ErrorBanner message="Could not create access code. Please check your internet connection and try again." />
        )}
        <div className="flex justify-center">
          <Button
            disabled={submitState === 'SUBMITTING'}
            loading={submitState === 'SUBMITTING'}
            onClick={() => {
              void submit();
            }}
            size="lg"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
