import { Page } from '@/components/Page';

import { NewEmployerRespiratorPageContent } from './NewEmployerRespiratorPageContent';

export function NewEmployerRespiratorPage() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Respirators',
          path: '/employer-respirators'
        },
      ]}
      title="Add Respirator"
    >
      <NewEmployerRespiratorPageContent />
    </Page>
  );
}
