import { ReactNode } from 'react';

export function TableCell({
  children,
  colSpan,
  firstCol = false,
  header = false,
  lastCol = false,
  vAlign,
}: {
  children: ReactNode;
  colSpan?: number;
  firstCol?: boolean;
  header?: boolean;
  lastCol?: boolean;
  vAlign?: 'bottom' | 'center' | 'top';
}) {
  return (
    <td
      className={`
        ${firstCol ? 'pl-4 sm:pl-6' : 'pl-3'}
        ${lastCol ? 'pr-4 sm:pl-6' : 'pr-3'}
        ${header ? 'font-semibold': ''}
        whitespace-nowrap py-4
        ${
          vAlign === 'bottom'
            ? 'align-bottom'
            : vAlign === 'center'
              ? 'align-middle'
              : vAlign === 'top'
                ? 'align-top'
                : ''
        }
      `}
      colSpan={colSpan}
    >
      {children}
    </td>
  );
}
