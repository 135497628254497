export function CheckboxInput({
  checked,
  id,
  name,
  onChange,
}: {
  checked: boolean;
  id?: string;
  name?: string;
  onChange?: (_: boolean) => void;
}) {
  return (
    <input
      checked={checked}
      className={`
        cursor-pointer h-4 w-4 rounded border-gray-300 text-purple-600 
        focus:ring-purple-600 focus:ring-offset-gray-920
      `}
      id={id}
      name={name}
      onChange={(evt) => {
        if (onChange !== undefined) {
          onChange(evt.target.checked);
        }
      }}
      type="checkbox"
    />
  );
}
