export function todayIso(): string {
  const now = new Date();

  // Assemble an ISO date string from parts
  const year = now.getFullYear().toString();
  // JS months are zero-indexed because of course they are
  let month = (now.getMonth() + 1).toString();
  let day = now.getDate().toString();

  // Zero-pad as necessary
  if (month.length < 2) {
    month = `0${month}`;
  }

  if (day.length < 2) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
}
