import { ChangeEventHandler } from 'react';

export function TextareaInput({
  className = '',
  onChange,
  maxLength,
  placeholder,
  rows = 5,
  value,
}: {
  className?: string,
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  maxLength?: number;
  placeholder?: string;
  rows?: number;
  value: string;
}) {
  return (
    <textarea
      className={`
        block w-full rounded-md border-0 py-1.5 bg-gray-900 resize-none
        shadow-sm ring-1 ring-inset ring-gray-800 placeholder:text-gray-700
        focus:ring-2 focus:ring-purple-800
        ${className}
      `}
      maxLength={maxLength}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      value={value}
    />
  );
}
