import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Page, PagePadder } from '@/components/Page';
import { useApi } from '@/hooks/useApi';
import { useLocationState } from '@/hooks/useLocationState';
import { Employer, GetEmployerData } from '@/types/api';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';

export function EmployerBasePage() {
  const params = useParams();

  const { data } = useApi<GetEmployerData>({
    path: `/employers/${params.employerPrn}`,
  });

  const locationState = useLocationState<{ employer: Employer; }>();

  const [employer, setEmployer] = useState<Employer | null>(
    locationState?.employer !== undefined ? locationState.employer : null,
  );

  useEffect(() => {
    if (data !== undefined) {
      setEmployer(data.employer);
    }
  }, [data]);

  return employer === null ? (
    <PagePadder>
      <PageBodySkeleton />
    </PagePadder>
  ) : (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Employers',
          path: '/employers',
        },
      ]}
      subNav={[
        // {
        //   icon: 'settings',
        //   label: 'Settings',
        //   path: `/employers/${employer.prn}`,
        // },
        // {
        //   icon: 'badge',
        //   label: 'Employees',
        //   path: `/employers/${employer.prn}/employees`,
        // },
        {
          icon: 'location_on',
          label: 'Locations',
          path: `/employers/${employer.prn}/locations`,
        },
        {
          icon: 'work',
          label: 'Job Roles',
          path: `/employers/${employer.prn}/job-roles`,
        },
        {
          icon: 'qr_code',
          label: {
            xs: 'Codes',
            sm: 'Access Codes',
          },
          path: `/employers/${employer.prn}/questionnaire-access-codes`,
        },
        {
          icon: 'masks',
          label: 'Respirators',
          path: `/employers/${employer.prn}/employer-respirators`,
        },
        {
          icon: 'manage_accounts',
          label: 'Users',
          path: `/employers/${employer.prn}/users`,
        },
      ]}
      title={employer.preferredName}
    >
      <Outlet />
    </Page>
  );
}
