import { Page } from '@/components/Page';

import { JobRolesListPageContent } from './JobRolesListPageContent';

export function JobRolesListPage() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Job Roles"
    >
      <JobRolesListPageContent />
    </Page>
  );
}
