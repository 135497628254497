import QRCode from 'react-qr-code';

export function QrCode({
  employerName,
  name,
  url,
}: {
  employerName: string;
  name: string;
  url: string;
}) {
  return (
    <div className="flex flex-col gap-y-4 items-center">
      <QRCode value={url} />
      <div>
        <div className="text-gray-950 text-center font-semibold">
          {employerName}
        </div>
        <div className="text-gray-950 text-center font-semibold text-xl">
          {name}
        </div>
      </div>
    </div>
  );
}
