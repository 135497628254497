import { Link } from '@/components/Link';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { BreadcrumbData } from '@/types/data';

export function Breadcrumb({
  label,
  path,
}: BreadcrumbData) {
  return (
    <li className="flex items-center space-x-1">
      <Link to={path}>{label}</Link>
      <MaterialSymbol icon="chevron_right" />
    </li>
  );
}
