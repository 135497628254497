import { Page } from '@/components/Page';

import { UsersListPageContent } from './UsersListPageContent';

export function UsersListPage() {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Users"
    >
      <UsersListPageContent />
    </Page>
  );
}
