import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import primaryLogoIconOnly from '@/assets/primary-logo-icon-only.png';
import { MaterialSymbol } from '@/components/MaterialSymbol';

import { useAppContext, useAuthContext, useNavContext } from '../hooks';

export function TopBar() {
  return (
    <div className="sticky flex items-center gap-x-4 top-0 z-30 bg-gray-960 border-b border-gray-900 h-16 px-4 sm:px-6 lg:px-8">
      <TopBarHamburger />
      <TopBarDivider mobileOnly />
      <TopBarMobileLogo />
      <TopBarAccountMenu />
      <TopBarDivider />
      <TopBarUserMenu />
    </div>
  );
}

function TopBarAccountMenu() {
  const { accounts, currentAccount, failed, isLoading } = useAppContext();

  return (
    <div className="grow h-full flex flex-row-reverse items-center min-w-0">
      {failed ? (
        null
      ) : isLoading ? (
        <TopBarSkeleton />
      ) : currentAccount === undefined ? (
        null
      ) : accounts.length === 1 ? (
        <TopBarSingleAccountLabel currentAccountName={currentAccount.preferredName} />
      ) : (
        <TopBarMultipleAccountsMenu currentAccountName={currentAccount.preferredName} />
      )}
    </div>
  );
}

function TopBarDivider({
  mobileOnly = false,
}: {
  mobileOnly?: boolean;
}) {
  return (
    <div aria-hidden="true" className={`h-6 w-px bg-gray-900 ${mobileOnly ? 'lg:hidden' : ''}`} />
  );
}

function TopBarHamburger() {
  const { setMainNavOpen } = useNavContext();

  return (
    <button
      className="-m-2.5 p-2.5 lg:hidden"
      onClick={() => { setMainNavOpen(true); }}
      type="button"
    >
      <span className="sr-only">open sidebar</span>
      <MaterialSymbol className="block" icon="menu" />
    </button>
  );
}

function TopBarMultipleAccountsMenu({
  currentAccountName,
}: {
  currentAccountName: string;
}) {
  const navigate = useNavigate();

  return (
    <Menu as="div" className="relative h-full flex items-center min-w-0">
      <Menu.Button className="flex items-center overflow-x-hidden">
        <span className="sr-only">open account menu</span>
        <span aria-hidden="true" className="truncate text-sm font-semibold leading-6">
          {currentAccountName}
        </span>
        <MaterialSymbol icon="expand_more" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 top-full z-10 mt-px -mr-4 w-56 origin-top-right rounded-b-md bg-gray-960 py-2 shadow-lg ring-1 ring-gray-910 focus:outline-none">
          <Menu.Item>
            {({ active, close }) => (
              <a
                className={`
                  ${active ? 'bg-gray-900' : ''}
                  flex gap-x-2 px-3 py-2 text-sm leading-6
                `}
                href="#"
                onClick={(evt) => {
                  evt.preventDefault();
                  navigate('/select-account');
                  close();
                }}
              >
                <MaterialSymbol icon="logout" />
                Switch Account
              </a>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function TopBarMobileLogo() {
  return (
    <img alt="Proxima" className="h-6 w-auto lg:hidden" src={primaryLogoIconOnly} />
  );
}

function TopBarSingleAccountLabel({
  currentAccountName,
}: {
  currentAccountName: string;
}) {
  return (
    <div className="truncate text-sm font-semibold">
      {currentAccountName}
    </div>
  );
}

function TopBarSkeleton() {
  return (
    <div className="bg-gray-50/10 rounded-full animate-pulse h-4 w-32" />
  );
}

function TopBarUserMenu() {
  const { logOut, user } = useAuthContext();

  return (
    <div className="relative h-full">
      <Menu as="div" className="h-full flex items-center">
        <Menu.Button className="flex items-center gap-x-4">
          <span className="sr-only">open user menu</span>
          <div className="h-8 w-8 rounded-full bg-green-700 flex items-center justify-center">
            <MaterialSymbol className="block" icon="person" />
          </div>
          <span className="hidden lg:flex lg:items-center">
            <span aria-hidden="true" className="text-sm font-semibold leading-6">
              {user.name}
            </span>
            <MaterialSymbol icon="expand_more" />
          </span>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 top-full z-10 mt-px -mr-4 w-56 origin-top-right rounded-b-md bg-gray-960 py-2 shadow-lg ring-1 ring-gray-910 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <Link
                  className={`
                    ${active ? 'bg-gray-900' : ''}
                    flex gap-x-2 px-3 py-2 text-sm leading-6
                  `}
                  to="/profile"
                >
                  <MaterialSymbol icon="person" />
                  My profile
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={`
                    ${active ? 'bg-gray-900' : ''}
                    flex gap-x-2 px-3 py-2 text-sm leading-6
                  `}
                  href="#"
                  onClick={(evt) => {
                    evt.preventDefault();
                    logOut();
                  }}
                >
                  <MaterialSymbol icon="logout" />
                  Sign Out
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
