export function openDownload(url: string) {
  const userAgent = navigator.userAgent;

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
  const isSafari = userAgent.includes('Safari')
    && !userAgent.includes('Chrome')
    && !userAgent.includes('Chromium');

  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;

  // Safari refuses to asynchronously open links in a new tab
  if (!isSafari) {
    link.target = '_blank';
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function scrollToClass(className: string) {
  setTimeout(() => {
    const els = document.getElementsByClassName(className);

    if (els.length > 0) {
      const firstEl = els[0] as HTMLElement;

      scrollTo({
        behavior: 'smooth',
        // Leave 74px for the top bar
        top: firstEl.offsetTop - 74,
      });
    }
  }, 50);
}

export function scrollToId(id: string) {
  setTimeout(() => {
    const el = document.getElementById(id);

    if (el !== null) {
      scrollTo({
        behavior: 'smooth',
        // Leave 74px for the top bar
        top: el.offsetTop - 74,
      });
    }
  }, 50);
}
