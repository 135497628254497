import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { MainNav } from './MainNav';
import { TopBar } from './TopBar';
import { navContext } from '../contexts';

export function MainLayout() {
  const [mainNavOpen, setMainNavOpen] = useState(false);

  return (
    <navContext.Provider value={{
      mainNavOpen,
      setMainNavOpen
    }}>
      <MainNav />
      <div className="lg:pl-72">
        <TopBar />
        <main className="py-6 lg:py-10">
          <Outlet />
        </main>
      </div>
    </navContext.Provider>
  );
}
