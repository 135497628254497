export function MaterialSymbol({
  block,
  className = '',
  icon,
}: {
  block?: boolean;
  className?: string;
  icon: string;
}) {
  return (
    <span
      aria-hidden="true"
      className={`material-symbols-outlined ${block === true ? 'block' : ''} ${className}`}
    >
      {icon}
    </span>
  );
}
