import { ReactNode } from 'react';

export function TableHeaderRow({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <tr>
      {children}
    </tr>
  );
}
