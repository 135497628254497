import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from '@/components/Table';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { AccountUser, QueryApiResponseData } from '@/types/api';

// TODO | Remove this once there's a better way
const HIDDEN_USER_PRNS = [
  'prn:p:acu:98cc6ca9-7ac2-4998-a8f6-8912e50eeff0',
  'prn:p:acu:5de3b22a-9e1f-4b7b-aef1-6c2cfe5fbde3',
];

export function UsersListPageContent() {
  const { currentAccount, currentEmployerPrn } = useAppContext();
  const navigate = useNavigate();

  const useAccountPrn = currentEmployerPrn ?? currentAccount?.prn;

  const { data } = useApi<QueryApiResponseData<AccountUser>>({
    disable: useAccountPrn === undefined,
    path: `/account-users?accountPrn=${useAccountPrn}`,
  });

  return data === undefined ? (
    <PageBodySkeleton />
  ) : (
    <div className="flex flex-col gap-y-4">
      <div className="px-4 sm:px-6 md:px-0 self-end">
        <Button
          onClick={() => {
            navigate(
              (currentAccount?.accountType === AccountType.SERVICE_PROVIDER
                  && currentEmployerPrn !== null)
                ? `/employers/${currentEmployerPrn}/users/new`
                : '/users/new',
            );
          }}
        >
          <MaterialSymbol icon="add" />
          Add New User
        </Button>
      </div>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell firstCol>Name</TableHeaderCell>
            <TableHeaderCell lastCol>Email Address</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {data.data.length === 0 ? (
            <TableRow>
              <TableCell colSpan={2} firstCol lastCol>No Users Found</TableCell>
            </TableRow>
          ) : data.data.map((accountUser) => (
            !HIDDEN_USER_PRNS.includes(accountUser.prn) && (
              <TableRow key={accountUser.prn}>
                <TableCell firstCol header>{accountUser.firstName} {accountUser.lastName}</TableCell>
                <TableCell lastCol>{accountUser.emailAddress}</TableCell>
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
