import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { AccountType } from '@/features/accounts';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import { useLocationState } from '@/hooks/useLocationState';
import { GetJobRoleData, JobRole } from '@/types/api';

import { JobRoleDetailsPageContent } from './JobRoleDetailsPageContent';

export function JobRoleDetailsPage() {
  const params = useParams();

  const { data } = useApi<GetJobRoleData>({
    path: `/job-roles/${params.jobRolePrn}`,
  });

  const locationState = useLocationState<{ jobRole: JobRole; }>();

  const [jobRole, setJobRole] = useState<JobRole | null>(locationState?.jobRole ?? null);

  useEffect(() => {
    if (data !== undefined) {
      // Bust react-router's cached state and replace it with the fetched data
      setJobRole(data.jobRole);
      window.history.replaceState({}, '');
    }
  }, [data]);

  const { currentAccount } = useAppContext();

  if (currentAccount === undefined || jobRole === null) {
    return <PageBodySkeleton />;
  } else if (currentAccount.accountType === AccountType.SERVICE_PROVIDER) {
    return (
      <div className="flex flex-col gap-4">
        <div className="font-display text-purple-200 pl-4 sm:pl-6">{jobRole.name}</div>
        <JobRoleDetailsPageContent jobRole={jobRole} setJobRole={setJobRole} />
      </div>
    );
  } else {
    return <JobRoleDetailsPageWrapper jobRole={jobRole} setJobRole={setJobRole} />
  }
}

export function JobRoleDetailsPageWrapper({
  jobRole,
  setJobRole,
}: {
  jobRole: JobRole;
  setJobRole: Dispatch<SetStateAction<JobRole | null>>;
}) {
  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
        {
          label: 'Job Roles',
          path: '/job-roles'
        },
      ]}
      title={jobRole.name}
    >
      <JobRoleDetailsPageContent jobRole={jobRole} setJobRole={setJobRole} />
    </Page>
  );
}
