import { FormGroup } from '@/components/FormGroup';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { NumberTextInput } from '@/components/NumberTextInput';

import { RespiratorFitTestProtocol, RespiratorFitTestResult } from '../enums';
import { CreateRespiratorFitTestFormData } from '../types';

export function FitFactorInput({
  error,
  formData,
  setFormData,
  submitState,
}: {
  error?: string;
  formData: CreateRespiratorFitTestFormData;
  setFormData: (_: CreateRespiratorFitTestFormData) => void;
  submitState: 'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS';
}) {
  let result: RespiratorFitTestResult | null = null;

  if (formData.employerRespirator !== null
      && formData.protocol === RespiratorFitTestProtocol.QUANTITATIVE) {
    if (/^(0|[1-9][0-9]*)$/.test(formData.quantitativeFitFactor)) {
      const numericFitFactor = parseInt(formData.quantitativeFitFactor);

      if (numericFitFactor >= 0 && numericFitFactor <= 1000000) {
        result = numericFitFactor >= formData.employerRespirator.passingQuantitativeFitFactor
          ? RespiratorFitTestResult.PASS
          : RespiratorFitTestResult.FAIL;
      }
    }
  }

  return formData.protocol === RespiratorFitTestProtocol.QUANTITATIVE && (
    <FormGroup error={error} label="Fit Factor">
      {(() => submitState === 'SUBMITTING' || submitState === 'SUCCESS' ? (
        <div>
          {formData.quantitativeFitFactor}
          {result !== null && ` (${result === RespiratorFitTestResult.PASS ? 'Pass' : 'Fail'})`}
        </div>
      ) : (
        <div className="flex items-center justify-start">
          <NumberTextInput
            className={`
              max-w-40
              ${result !== null ? 'rounded-r-none rounded-l' : ''}
            `}
            maxLength={7}
            onChange={(quantitativeFitFactor) => {
              setFormData({
                ...formData,
                quantitativeFitFactor,
              });
            }}
            value={formData.quantitativeFitFactor}
          />
          {result !== null && (
            <div className="font-semibold rounded-r border-y border-r border-gray-800 self-stretch px-2 flex items-center gap-1">
              <MaterialSymbol
                className="text-xl"
                icon={result === RespiratorFitTestResult.PASS ? 'check' : 'close'}
              />
              {result === RespiratorFitTestResult.PASS ? 'Pass' : 'Fail'}
            </div>
          )}
        </div>
      ))}
    </FormGroup>
  )
}
